import { ASSET_ITEM_STATUS_PARAM_TYPE, AssetSingleViewQueryParams } from 'common/models'
import queryString from 'query-string'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { cleanupAssetItem, cleanupAssetItemByValidAt, fetchAssetByValidAt } from 'store/asset/actions'
import { fetchCauseList } from 'store/settings/cause/actions'

export const useFetchInitData = (biid: string) => {
  const dispatch = useDispatch()
  const location = useLocation()
  useEffect(() => {
    const { valid_at, type: assetItemStatus } = queryString.parse(location.search) as AssetSingleViewQueryParams

    const isUpdateValidAtValue = [
      ASSET_ITEM_STATUS_PARAM_TYPE.TO_BE_ACQUIRED,
      ASSET_ITEM_STATUS_PARAM_TYPE.RETIRED_OR_SOLD,
    ].includes(assetItemStatus!)

    dispatch(fetchAssetByValidAt(biid, valid_at, isUpdateValidAtValue, true))

    dispatch(fetchCauseList())
    dispatch(fetchMonthlyClosingCurrent())

    return function cleanup() {
      dispatch(cleanupAssetItem())
      dispatch(cleanupAssetItemByValidAt())
    }
  }, [])
}
