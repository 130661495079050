import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { AssetNewContainer } from 'containers/assets/asset-new-container'

import { AssetNewPageOld } from './asset-new-page'

const AssetNewPage = () => {
  const { isEnable: isRefactorAssetNewPage } = useCheckFlag(FeatureFlagNames.RefactorPagePhase5)

  return <>{isRefactorAssetNewPage ? <AssetNewContainer /> : <AssetNewPageOld />}</>
}

export default AssetNewPage
