import { Asset } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import get from 'lodash/get'
import { useMemo } from 'react'

export const useRenderBreadcrumb = (dataByValidAt: Asset) => {
  return useMemo(() => {
    const pageName = `（${get(dataByValidAt, 'code') || ''}-${get(dataByValidAt, 'branch_code') || ''}）${
      get(dataByValidAt, 'name') || ''
    }`

    return <BreadcrumbNavigation enableBackButton sectionName={pageName} />
  }, [dataByValidAt])
}
