import { Spin } from 'aa_common/front-end/antd'
import { getNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import ReCalculateView from 'components/organisms/settings/ReCalculateView'
import { MainTemplate } from 'components/templates'
import React from 'react'

const { t, useNsReady } = getNs(['pages/settings/recalculate'])

const breadcrumb = (
  <BreadcrumbNavigation
    enableBackButton={false}
    sectionName={
      <div style={{ display: 'flex' }}>
        <span>{t('recalculate_title')}</span>
      </div>
    }
  />
)

export const ReCalculatePageOld = () => {
  useNsReady()
  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <Spin loading={false}>
        <ReCalculateView />
      </Spin>
    </MainTemplate>
  )
}
