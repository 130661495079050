import { Spin } from 'aa_common/front-end/antd'
import { RefactorWarningMessage } from 'common/components/refactor-warning-message'
import { getNs } from 'common/i18n-config'
import { ExciseSettingFormModal, ExciseSettingView } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import { isEqual } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTermsData } from 'store/settings/accountingPeriod/selectors'
import { selectExciseSettingListData, selectExciseSettingLoadingState } from 'store/settings/exciseSetting/selectors'

import { BreadCrumb } from './components/breadcrumb-component'
import { useExciseSettingModal } from './hooks/use-excise-setting-modal'
import { useFetchData } from './hooks/use-fetch-data'
import { useHandleDelete } from './hooks/use-handle-delete'

const { t, useNsReady } = getNs([
  'common',
  'pages/settings/excise-setting',
  'components/organisms/settings/excise-setting-view',
])

export const ExciseSettingContainer = () => {
  useNsReady()
  const dispatch = useDispatch()

  const terms = useSelector(selectTermsData, isEqual)
  const isLoading = useSelector(selectExciseSettingLoadingState)
  const exciseSettingListData = useSelector(selectExciseSettingListData, isEqual)

  useFetchData(dispatch)

  const { openModal, modalRef } = useExciseSettingModal(dispatch)

  const handleClickDelete = useHandleDelete(dispatch)

  return (
    <>
      <RefactorWarningMessage />
      <MainTemplate breadcrumb={<BreadCrumb />}>
        <Spin loading={isLoading}>
          <ExciseSettingView
            terms={terms}
            exciseSettingListData={exciseSettingListData}
            onClickDelete={handleClickDelete}
            onClickTopEdit={setting =>
              openModal({
                setting,
                title: t('actions.edit'),
              })
            }
            onClickEdit={setting =>
              openModal({
                setting,
                allowSelectTerm: false,
                title: t('actions.modify'),
              })
            }
            onClickAdd={setting =>
              openModal({
                setting,
                title: t('actions.add'),
              })
            }
          />
        </Spin>
        <ExciseSettingFormModal ref={modalRef} />
      </MainTemplate>
    </>
  )
}
