import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { CauseCopyPageContainer } from 'containers/master/causes/cause-copy-page-container'
import React from 'react'

import { CauseCopyPageOld } from './cause-copy-page'

const CauseCopyPage = () => {
  const { isEnable: isEnableCauseCopyPage } = useCheckFlag(FeatureFlagNames.RefactorPagePhase5)

  return <>{isEnableCauseCopyPage ? <CauseCopyPageContainer /> : <CauseCopyPageOld />}</>
}

export default CauseCopyPage
