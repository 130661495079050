import { FilterFunction, initAsyncSlice } from 'aa_common/front-end/zustand'
import { PresenterOneUnitResponse, UnitsService } from 'common/open-api'

const initFilter = {}
const initState: { units: PresenterOneUnitResponse[] } = {
  units: [],
}

const getUnits: FilterFunction<typeof initState, typeof initFilter> = async () => {
  try {
    const units = (await UnitsService.unitsList())?.data
    return { data: { units } }
  } catch (err: any) {
    return { data: { units: [] } }
  }
}

export const unitsSlice = initAsyncSlice<typeof initState, typeof initFilter>(
  'master/units',
  initState,
  initFilter,
  getUnits
)
