import { Button, ifElse } from 'aa_common/front-end'
import { Asset } from 'common/models'
import { DropdownMenuButton } from 'components/molecules'
import i18n from 'i18n'
import { get } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { changeLocation } from 'store/app/actions'

import { useRenderTopMessage } from '../hooks'
import { Section } from '../styles'

interface Props {
  dataByValidAt: Asset
  registerEvent: string
  isShowingEventForm: boolean
  isRetiredOrSold: boolean
  fixedAssetPermissions: any
  dropdownMenuSettings: any
  asset: Asset
}

export const TopMessage = ({
  dataByValidAt,
  registerEvent,
  isShowingEventForm,
  isRetiredOrSold,
  fixedAssetPermissions,
  dropdownMenuSettings,
  asset,
}: Props) => {
  const dispatch = useDispatch()

  const handleEditClick = () => {
    dispatch(changeLocation(`/assets/${get(asset, 'biid')}/edit`))
  }
  return (
    <Section className="top">
      {useRenderTopMessage(dataByValidAt, registerEvent)}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
        <Button
          color="grey"
          size="custom"
          style={{ minWidth: 56, marginRight: 10 }}
          onClick={handleEditClick}
          disabled={isShowingEventForm || isRetiredOrSold || !fixedAssetPermissions?.isEdit}
          showTooltipMessage={ifElse(!fixedAssetPermissions?.isEdit, i18n.t('common.actions.no_permission'), undefined)}
        >
          {i18n.t('common.actions.edit')}
        </Button>
        <DropdownMenuButton
          style={{ minWidth: 56, padding: '0 5px' }}
          trigger={['hover']}
          size="custom"
          menuSettings={dropdownMenuSettings}
          disabled={isShowingEventForm}
        >
          {i18n.t('pages.assets.actions.change_situation')}
        </DropdownMenuButton>
      </div>
    </Section>
  )
}
