import { ifElse, someTrue } from 'aa_common/front-end'
import { FEATURES_ID } from 'common/constants'
import { handlePermissionByFeatureId } from 'common/helpers'
import { Asset } from 'common/models'
import i18n from 'i18n'
import { get } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectUserPermission } from 'store/session/selectors'

import { EVENT_TYPES } from '../constants'
import { disableReasonTooltip, getImpairmentMenuSettingMemo, isInapplicableForImpairment } from '../helpers'
import { useCheckIsDividingable } from './use-check-is-dividingable'

export const useGetDropdownMenuSetting = (isRetiredOrSold: boolean, asset: Asset, showFormEvent: Function) => {
  const { data: userPermissions } = useSelector(selectUserPermission)
  const startUsagePermissions = handlePermissionByFeatureId(FEATURES_ID.START_USAGE, userPermissions)
  const movementPermissions = handlePermissionByFeatureId(FEATURES_ID.MOVEMENT, userPermissions)
  const retirePermissions = handlePermissionByFeatureId(FEATURES_ID.RETIRE, userPermissions)
  const salePermissions = handlePermissionByFeatureId(FEATURES_ID.SALE, userPermissions)
  const impairmentPermissions = handlePermissionByFeatureId(FEATURES_ID.IMPAIRMENT, userPermissions)
  const typeModifyPermissions = handlePermissionByFeatureId(FEATURES_ID.TYPE_MODIFICATION, userPermissions)
  const dividingPermissions = handlePermissionByFeatureId(FEATURES_ID.DIVIDING, userPermissions)
  const isDividingAble = useCheckIsDividingable(isRetiredOrSold)

  return useMemo(
    () => [
      {
        label: i18n.t('components.AssetUsageStartedForm.usage_started'),
        onClick: () => startUsagePermissions.isCreate && showFormEvent(EVENT_TYPES.USAGE_STARTED)(true),
        disabled: someTrue(!!get(asset, 'usage_started_at'), isRetiredOrSold, !startUsagePermissions.isCreate),
        tooltip: ifElse(startUsagePermissions.isCreate, undefined, disableReasonTooltip),
      },
      {
        label: i18n.t('components.AssetMovementForm.movement'),
        onClick: () => movementPermissions.isCreate && showFormEvent(EVENT_TYPES.MOVEMENT)(true),
        disabled: someTrue(isRetiredOrSold, !movementPermissions.isCreate),
        tooltip: ifElse(movementPermissions.isCreate, undefined, disableReasonTooltip),
      },
      {
        label: i18n.t('components.AssetRetirementForm.retirement'),
        onClick: () => showFormEvent(EVENT_TYPES.RETIREMENT)(true),
        disabled: someTrue(isRetiredOrSold, !retirePermissions.isCreate),
        tooltip: ifElse(retirePermissions.isCreate, undefined, disableReasonTooltip),
      },
      {
        label: i18n.t('components.AssetEventSellForm.sale'),
        onClick: () => showFormEvent(EVENT_TYPES.SOLD)(true),
        disabled: someTrue(isRetiredOrSold, !salePermissions.isCreate),
        tooltip: ifElse(salePermissions.isCreate, undefined, disableReasonTooltip),
      },
      ...ifElse(
        !isInapplicableForImpairment(asset),
        [
          {
            label: i18n.t('components.AssetImpairment.impairment'),
            onClick: () => showFormEvent(EVENT_TYPES.IMPAIRMENT)(true),
            ...getImpairmentMenuSettingMemo(impairmentPermissions?.isCreate, asset, isRetiredOrSold),
          },
        ],
        []
      ),
      ...[
        {
          label: i18n.t('components.AssetDividing.dividing'),
          onClick: () => showFormEvent(EVENT_TYPES.DIVIDING)(true),
          disabled: someTrue(!isDividingAble, !someTrue(dividingPermissions.isCRU, dividingPermissions.isCRUD)),
          tooltip: ifElse(dividingPermissions.isCRU || dividingPermissions.isCRUD, undefined, disableReasonTooltip),
        },
      ],
      {
        label: i18n.t('components.AssetTypeModifyForm.modify_type'),
        onClick: () => showFormEvent(EVENT_TYPES.TYPE_MODIFICATION)(true),
        disabled: !typeModifyPermissions.isCreate,
        tooltip: ifElse(typeModifyPermissions.isCreate, undefined, disableReasonTooltip),
      },
    ],
    [asset, impairmentPermissions, isRetiredOrSold, isDividingAble, dividingPermissions]
  )
}
