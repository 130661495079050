import { initSlice } from 'aa_common/front-end/zustand'
import { PresenterFullOneFixedAssetResponse } from 'common/open-api'
import { produce } from 'immer'

const initState: {
  data: any
  importBulkChangeType: any
  errorBiids: string[]
  errors: any
} = {
  data: {},
  importBulkChangeType: null,
  errorBiids: [],
  errors: [],
}

export const importBulkChangeSlice = initSlice('AI/bulk-change-import', initState)

export const addState = (state: any, type: any) => {
  importBulkChangeSlice.setState({ data: state, importBulkChangeType: type })
}

export const reset = () => {
  importBulkChangeSlice.setState(initState)
}

export const setErrorBiids = (errorBiids: any) => {
  importBulkChangeSlice.setState(state =>
    produce(state, draft => {
      draft.errorBiids = errorBiids
    })
  )
}

export const setErrors = (errors: any) => {
  importBulkChangeSlice.setState(state =>
    produce(state, draft => {
      draft.errors = errors
    })
  )
}

export const clearErrors = () => {
  importBulkChangeSlice.setState(state =>
    produce(state, draft => {
      draft.errors = []
    })
  )
}

export const importBulkChangeAction = {
  addState,
  reset,
  setErrorBiids,
  setErrors,
  clearErrors,
}

type AssetImportState = {
  data: PresenterFullOneFixedAssetResponse[]
  errors: any[]
}

const assetImportInitState: AssetImportState = {
  data: [],
  errors: [],
}
export const assetImportSlice = initSlice('AI/asset-import', assetImportInitState)

export const assetImportActions = {
  setState: ({ data, errors }: AssetImportState) => {
    assetImportSlice.setState({ data, errors })
  },
}
