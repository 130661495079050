import { If } from 'aa_common/front-end/components'
import { ifElse } from 'aa_common/front-end/helpers'
import { Checkbox } from 'antd'
import { EMPTY_SYMBOL, EXCISE_TAX_METHOD } from 'common/constants'
import { formatNumber, yen } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { FileLink, ReferenceLink, TagView } from 'components/atoms'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React from 'react'
import { useSelector } from 'react-redux'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'

import { TableWrapper } from '../styles'

type Props = {
  data: any
}
const { t, useNsReady } = getNs(['components/organisms/assets/asset-detail'])

const Information: React.FC<Props> = ({ data }) => {
  useNsReady()
  const { isEnable: isEnableForReduction } = useCheckFlag(FeatureFlagNames.FixedAssetReduction)
  const renderAttachments = (attachments: any[]) => {
    return attachments.map((item: any) => <FileLink file={item} key={item.id} style={{ marginRight: 10 }} />)
  }

  const renderUrls = (urls: string[]) =>
    urls.map((url: string) => <ReferenceLink url={url} key={url} style={{ marginRight: 10 }} />)

  const renderTags = (tags: any[]) => {
    return tags.map((tag: any) => (
      <TagView
        key={tag?.id || tag}
        content={get(tag, 'name') || ''}
        style={{
          marginRight: 5,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      />
    ))
  }

  const renderExemtionFlag = (asset: any) => {
    let checked = false
    if (get(asset, 'exemption_numerator') > 0 || get(asset, 'exemption_denominator') > 0) {
      checked = true
    }

    return <Checkbox disabled checked={checked} />
  }

  const renderExemtionValues = (asset: any) => {
    if (get(asset, 'exemption_numerator') > 0 || get(asset, 'exemption_denominator') > 0) {
      return `${get(asset, 'exemption_numerator') || ''}/${get(asset, 'exemption_denominator') || ''}`
    }
    return 'ー'
  }

  const renderAssetTypeCorporateTax = (data: any) => {
    let result = ''

    if (get(data, ['asset_type_for_corporate_tax', 'name_jp']))
      result += `${get(data, ['asset_type_for_corporate_tax', 'name_jp'])}\n`

    if (get(data, 'asset_structure_for_corporate_tax')) result += `${get(data, 'asset_structure_for_corporate_tax')}\n`

    if (get(data, 'asset_detail_for_corporate_tax')) result += get(data, 'asset_detail_for_corporate_tax')

    return result.trim()
  }

  const { data: exciseSetting } = useSelector(getExciseSettingsByDateList, isEqual)
  const isTaxMethodExcluded = exciseSetting?.tax_method === EXCISE_TAX_METHOD.EXCLUDED_TAX
  const acquisitionCost = ifElse(
    isTaxMethodExcluded,
    get(data, 'acquisition_cost_excluded_excise'),
    get(data, 'acquisition_cost')
  )
  const subsidyAmount = get(data, 'subsidy_amount')
  const reductionAmount = get(data, 'reduction_amount')

  return (
    <>
      <div style={{ display: 'flex' }}>
        <TableWrapper className="name-table">
          <tbody>
            <tr>
              <th>{t('acquisition_category')}</th>
              <td>{get(data, ['acquire_category', 'name_jp'])}</td>
            </tr>
            <tr>
              <th>{t('asset_category')}</th>
              <td>{get(data, ['asset_category', 'name'])}</td>
            </tr>
            <tr>
              <th>{t('asset_type_for_depreciable_property_tax')}</th>
              <td>{get(data, ['asset_type_for_depreciable_property_tax', 'name_jp'])}</td>
            </tr>
            <tr>
              <th>{t('asset_type_for_corporate_tax')}</th>
              <td style={{ whiteSpace: 'pre-line' }}>{renderAssetTypeCorporateTax(data)}</td>
            </tr>
            <tr>
              <th>{t('area')}</th>
              <td>{get(data, ['area', 'name'])}</td>
            </tr>
            <tr>
              <th>{t('submit_destination')}</th>
              <td>{get(data, ['area', 'submit_destination', 'name'])}</td>
            </tr>
            <tr>
              <th>{t('department')}</th>
              <td>{get(data, ['department', 'name'])}</td>
            </tr>
          </tbody>
        </TableWrapper>
        <TableWrapper style={{ marginLeft: 10 }} className="quantity-table">
          <tbody>
            <tr>
              <th>{t('quantity')}</th>
              <td>
                {formatNumber(get(data, 'quantity'))}
                {ifElse(get(data, ['unit', 'name']), `（${get(data, ['unit', 'name'])}）`, '')}
              </td>
            </tr>
            <tr>
              <th>{t('acquire_destination')}</th>
              <td>{get(data, 'acquire_destination_name')}</td>
            </tr>
            <tr>
              <th>{t('exemption_flag')}</th>
              <td>{data && renderExemtionFlag(data)}</td>
            </tr>
            <tr>
              <th>{t('taxation_standard_special')}</th>
              <td>{data && renderExemtionValues(data)}</td>
            </tr>
            <tr>
              <th>{t('summary')}</th>
              <td>{get(data, 'summary_for_asset_depreciation_tax') || EMPTY_SYMBOL}</td>
            </tr>
          </tbody>
        </TableWrapper>
      </div>
      <If condition={isEnableForReduction && subsidyAmount && reductionAmount}>
        <TableWrapper style={{ marginTop: 10 }} className="reduction-table">
          <tbody>
            <tr>
              <th>{t('subsidy_amount')}</th>
              <td>{yen(subsidyAmount)}</td>
              <th>{t('net_acquisition_cost')}</th>
              <td>{yen(acquisitionCost - subsidyAmount)}</td>
            </tr>
            <tr>
              <th>{t('reduction_amount')}</th>
              <td>{yen(reductionAmount)}</td>
              <th>{t('reduced_acquisition_cost')}</th>
              <td>{yen(acquisitionCost - reductionAmount)}</td>
            </tr>
          </tbody>
        </TableWrapper>
      </If>
      <TableWrapper style={{ marginTop: 10 }} className="attachment-table">
        <tbody>
          <tr>
            <th>{t('attachment')}</th>
            <td>{renderAttachments(get(data, 'attachments') || [])}</td>
          </tr>
          <tr>
            <th>{t('reference')}</th>
            <td>{renderUrls(get(data, 'urls') || [])}</td>
          </tr>
        </tbody>
      </TableWrapper>
      <TableWrapper style={{ marginTop: 10 }} className="tag-table">
        <tbody>
          <tr>
            <th>{t('tag')}</th>
            <td>{renderTags(get(data, 'tags') || [])}</td>
          </tr>
          <tr>
            <th>{t('memo')}</th>
            <td style={{ wordBreak: 'break-all' }}>{get(data, 'memo')}</td>
          </tr>
        </tbody>
      </TableWrapper>
    </>
  )
}

export default React.memo(Information)
