import { Spin } from 'aa_common/front-end/antd'
import { If } from 'aa_common/front-end/components'
import { Button } from 'antd'
import * as assetApi from 'api/app/asset'
import * as depreciationRatesApi from 'api/app/depreciationRates'
import { DATE_FORMAT, DEPRECIATION_METHOD_CODE, EXCISE_TAX_METHOD, LEDGER_PURPOSE } from 'common/constants'
import { isAccountingJournalTarget, isTaxJournalTarget } from 'common/helpers/ledger-helper'
import { getNs } from 'common/i18n-config'
import { Asset, AssetLedger, LedgerSetting } from 'common/models'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import useResource from 'lib/hooks/useResource'
import { get, isEqual } from 'lodash'
import last from 'lodash/last'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { selectEventHistories } from 'store/asset/selectors'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

import AmortizationInfoTable from './components/AmortizationInfoTable'
import { isChangeDepreciationMethod } from './components/AmortizationInfoTable/tables/helper'
import { DepreciationAdjustmentDropdown } from './components/depreciation-adjustment-dropdown'
import DepreciationTable from './components/DepreciationTable'
import { Section, SectionHeaderText, StyledSectionHeader, Wrapper, WrapperLedger } from './styles'

interface IAmortization {
  data: Asset
}

const { t, useNsReady } = getNs('components/organisms/assets/asset-detail')
const Amortization = ({ data }: IAmortization) => {
  useNsReady()
  const { isEnable: isEnableForRegisterAcceleratedDepreciation } = useCheckFlag(
    FeatureFlagNames.RegisterAcceleratedDepreciation
  )
  const { state }: any = useLocation()
  const [selectedAssetLedger, setSelectedAssetLedger] = useState<AssetLedger | null>(null)
  const ledgerSettings: LedgerSetting[] = useSelector(selectLedgerSettingList, isEqual)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const { data: exciseSetting } = useSelector(getExciseSettingsByDateList, isEqual)

  const isTaxMethodExcluded = exciseSetting?.tax_method === EXCISE_TAX_METHOD.EXCLUDED_TAX

  const hasUsageStarted = !!data.usage_started_at

  const [depreciationResults, getDepreciationResults] = useResource(assetApi.getDepreciationResults)

  const [depreciationRate, getDepreciationRate] = useResource(depreciationRatesApi.get)
  const [depreciationRateData, setRenderDepreciationRate] = useState(null)
  const [isShowComparisonTable, setShowComparisonTable] = useState<boolean>(false)

  const eventHistories = useSelector(selectEventHistories, isEqual)
  const lastRecord = useMemo(() => last(eventHistories), [eventHistories])
  const dividedAts: string[] = []
  eventHistories?.forEach(eventHistory => {
    const dividedAt = get(eventHistory, 'divided_at', null)
    if (dividedAt) dividedAts.push(dividedAt)
  })
  const isSourceAsset = !get(lastRecord, 'dividing_source_fixed_asset', null)

  const getLedgerByType = useCallback(
    (type: LEDGER_PURPOSE) => {
      return data?.fixed_asset_ledgers?.find(ledger => ledger?.ledger_setting?.ledger_purpose === type)
    },
    [data?.fixed_asset_ledgers]
  )

  const isShowComparisonButton = useMemo(() => {
    const taxLedger = getLedgerByType(LEDGER_PURPOSE.TAX)
    const accountingLedger = getLedgerByType(LEDGER_PURPOSE.ACCOUNTING)

    return !!(
      accountingLedger &&
      taxLedger &&
      taxLedger?.depreciation_method_code !== DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE &&
      taxLedger?.depreciation_method_code !== DEPRECIATION_METHOD_CODE.NON_DEPRECIATION
    )
  }, [getLedgerByType])

  const isDeclineBalanceMethods = useMemo(() => {
    const taxLedger = getLedgerByType(LEDGER_PURPOSE.TAX)
    return !!(
      taxLedger?.depreciation_method_code &&
      [
        DEPRECIATION_METHOD_CODE.OLD_DECLINING_BALANCE,
        DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_250,
        DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_200,
      ].includes(taxLedger.depreciation_method_code)
    )
  }, [getLedgerByType])

  useEffect(() => {
    if (data.fixed_asset_ledgers?.length > 0 && !selectedAssetLedger) {
      setSelectedAssetLedger(data.fixed_asset_ledgers[0])
    }
  }, [data, selectedAssetLedger])

  const filteredLedgerSettings: LedgerSetting[] = useMemo(() => {
    if (!ledgerSettings || !data.fixed_asset_ledgers) return []

    const assetLedgerSettingIds: number[] = data.fixed_asset_ledgers.map((ledger: any) => {
      return ledger.ledger_setting_id
    })
    return ledgerSettings.filter(ledgerSetting => {
      return assetLedgerSettingIds.includes(ledgerSetting.id as number)
    })
  }, [ledgerSettings, data.fixed_asset_ledgers]) // eslint-disable-line

  const handleLedgerChange = (value: any) => {
    if (!value) return handleShowComparisonTable()

    const selectedLedger = data.fixed_asset_ledgers.find(assetLedger => assetLedger.ledger_setting_id === value) || null
    setShowComparisonTable(false)
    setSelectedAssetLedger(selectedLedger)
  }

  useEffect(() => {
    if (data && selectedAssetLedger && !isShowComparisonTable) {
      const { biid } = data
      getDepreciationResults(biid, selectedAssetLedger.ledger_setting_id)
    }
  }, [getDepreciationResults, selectedAssetLedger, data, isShowComparisonTable])

  useEffect(() => {
    const depreciationRateValue = get(depreciationRate.data, '0.depreciation_rate')
    setRenderDepreciationRate(depreciationRateValue)
  }, [depreciationRate.data])

  const depreciationCostValue: number = useMemo((): number => {
    return isTaxMethodExcluded ? data?.acquisition_cost_excluded_excise : data?.acquisition_cost
  }, [data?.acquisition_cost, data?.acquisition_cost_excluded_excise])

  useEffect(() => {
    selectedAssetLedger &&
      !isShowComparisonTable &&
      getDepreciationRate({
        depreciation_method: selectedAssetLedger?.depreciation_method_code,
        service_life: isChangeDepreciationMethod(selectedAssetLedger, depreciationCostValue)
          ? selectedAssetLedger?.service_life_for_depreciation_calc
          : selectedAssetLedger?.service_life,
      })
  }, [getDepreciationRate, selectedAssetLedger, isShowComparisonTable, isChangeDepreciationMethod])

  useEffect(() => {
    const underOverDepreciationReportPath = '/depreciation/under-over-depreciation-report'
    if (state?.fromPath === underOverDepreciationReportPath) {
      setShowComparisonTable(true)
    }
  }, [state])

  const memoizedDepreciationResults = useMemo(() => {
    return depreciationResults.data || []
  }, [depreciationResults.data])

  const handleShowComparisonTable = () => {
    setShowComparisonTable(true)
  }

  const isAccountantLedger = isAccountingJournalTarget(selectedAssetLedger?.ledger_setting)

  return (
    <Wrapper>
      <If condition={hasUsageStarted}>
        <Spin loading={depreciationResults.isLoading}>
          <WrapperLedger>
            <If condition={filteredLedgerSettings.length > 0}>
              {filteredLedgerSettings.map(({ id, name }) => (
                <Button
                  key={id}
                  className={
                    selectedAssetLedger?.ledger_setting_id === id && !isShowComparisonTable ? 'active' : 'disable'
                  }
                  onClick={() => handleLedgerChange(id)}
                  shape="round"
                >
                  {name}
                </Button>
              ))}
            </If>

            <If condition={isShowComparisonButton}>
              <Button
                className={isShowComparisonTable ? 'active' : 'disable'}
                onClick={handleShowComparisonTable}
                shape="round"
              >
                {t('depreciation_comparison')}
              </Button>
            </If>
          </WrapperLedger>

          <Section>
            <StyledSectionHeader>
              <div>
                <If condition={!isShowComparisonTable}>
                  <SectionHeaderText>{t('depreciation_method')}</SectionHeaderText>
                  <SectionHeaderText>{`${moment(data.usage_started_at).format(DATE_FORMAT)}〜`}</SectionHeaderText>
                </If>
              </div>
              <If
                condition={isEnableForRegisterAcceleratedDepreciation && !isShowComparisonTable && isAccountantLedger}
              >
                <DepreciationAdjustmentDropdown />
              </If>
            </StyledSectionHeader>

            <If condition={selectedAssetLedger}>
              <AmortizationInfoTable
                ledger={selectedAssetLedger as AssetLedger}
                depreciationRate={depreciationRateData}
                biid={data?.biid}
                isShowComparisonTable={isShowComparisonTable}
                acquisitionCost={depreciationCostValue}
              />
            </If>
          </Section>

          {!isShowComparisonTable && (
            <Section>
              <SectionHeaderText>{t('depreciation_calculation')}</SectionHeaderText>
              <DepreciationTable
                dividedAts={dividedAts}
                isSourceAsset={isSourceAsset}
                acquiredAt={data.acquired_at as string}
                usageStartedAt={data.usage_started_at as string}
                retiredAt={data.retired_at as string}
                soldAt={data.sold_at as string}
                depreciationResults={memoizedDepreciationResults}
                currentMonthlyClosing={currentMonthlyClosing}
                impairAt={data.impaired_at as string}
                isAccountLedger={isAccountingJournalTarget(selectedAssetLedger?.ledger_setting)}
                isShowComparison={isShowComparisonButton && isTaxJournalTarget(selectedAssetLedger?.ledger_setting)}
                isDeclineBalanceMethods={isDeclineBalanceMethods}
                showComparisonTable={handleShowComparisonTable}
              />
            </Section>
          )}
        </Spin>
      </If>

      <If condition={!hasUsageStarted}>
        <span>{t('depreciation_calculation_not_begin_msg')}</span>
      </If>
    </Wrapper>
  )
}

export default Amortization
