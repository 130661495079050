import { Tooltip, UnderlineInput } from 'aa_common/front-end/antd'
import {
  FormikInfiniteScrollSelect,
  If,
  LinkLabel,
  RedirectToMasterPageLink,
  UnborderCollapse,
  UnderlineMoneyInput,
  VerticalLine,
} from 'aa_common/front-end/components'
import { defaultTo, ifElse, isNumberHaveValue } from 'aa_common/front-end/helpers'
import { useBoolean } from 'aa_common/front-end/hooks'
import { message } from 'antd'
import { search as searchAreas } from 'api/app/area'
import { calculateMemorandumValue as calculateMemorandumValueApi } from 'api/app/asset'
import * as assetCategoryApi from 'api/app/assetCategory'
import { search as searchDepartments } from 'api/app/department'
import * as exciseApi from 'api/app/excise'
import { TempDataTypes } from 'api/app/tempData'
import classNames from 'classnames'
import {
  ASSET_CODE_AUTO_GENERATE_SETTING_METHOD,
  ASSET_TYPE,
  CONFIRM_TYPES,
  DATE_FORMAT_RFC,
  DEFAULT_MEMORANDUM_VALUE,
  DEPRECIATION_METHOD_CODE,
  ENDPOINTS,
  ERR_EXCISE_NOT_VALID_AT_TIME,
  EXCISE_CAN_EDIT_TAX,
  EXCISE_TAX_INPUT_FORMAT,
  EXCISE_TAX_METHOD,
  EXECUTE_VAT,
  FEATURES_ID,
  LEDGER_PURPOSE,
} from 'common/constants'
import { handlePermissionByFeatureId } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { Asset, AssetLedger, FORM_MODES } from 'common/models'
import { PresenterOneTagResponse } from 'common/open-api'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import {
  AutoKanaInput,
  Checkbox,
  CopyIcon,
  HorizontalLine,
  Input,
  NumberInput,
  RegexInput,
  TextArea,
} from 'components/atoms'
import {
  AutoCompleteSelect,
  DatePicker,
  LinkListField,
  MoneyInput,
  TagSelect,
  UploadFileListField,
  UploadImageListField,
} from 'components/molecules'
import { ExciseSelect } from 'components/organisms'
import { tagListSlice } from 'containers/master/tags/store'
import { unitsSlice } from 'containers/master/units/store'
import { getIn, useFormikContext } from 'formik'
import useModalHook from 'hooks/useModalHook'
import parser from 'html-react-parser'
import useResource from 'lib/hooks/useResource'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import moment, { Moment } from 'moment'
import { OptionsType } from 'rc-select/lib/interface'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAppMessage } from 'store/app/actions'
import { selectAssetImportItemData } from 'store/assetImport/selectors'
import { selectUserPermission } from 'store/session/selectors'
import { selectTermsData } from 'store/settings/accountingPeriod/selectors'
import { IAssetCategoriesItem } from 'store/settings/assetCategory/model'
import { selectAssetCodeAutoGenerateSettingListData } from 'store/settings/assetCode/selectors'
import { fetchExciseSettingsByDate } from 'store/settings/excise/actions'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'
import { useDebounce } from 'use-debounce'

import { ItemLabelTooltip } from './components/ItemLabelTooltip'
import { SubmitArea } from './components/submit-area'
import { branchCodeField, codeField, nameField } from './const'
import DepreciationLedge from './DepreciationLedge'
import {
  checkAcquiredAtBelongToAssetCodeAutoGenerate,
  checkShowMemorandumValue,
  fetchNewExciseSetting,
  isDifferentExciseSetting,
  isFirstDayOfTerm,
  setTransitionAtValue,
} from './helper'
import { useAcquisitionCostReference } from './hooks/use-acquisition-cost-reference'
import { useBookValueReference } from './hooks/use-book-value-reference'
import { useSubsidyAmount } from './hooks/use-subsidy-amount'
import { useFormValidation, useSubmitOnError } from './hooks/useFormValidation'
import { AssetCodeWrapper, Error, FormGroup, Heading, HeadingTitle } from './styles'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

const { Item, LeftGroup, RightGroup } = UnborderCollapse

const AUTO_SAVE_DELAY_TIME = 2000
const DEFAULT_SERVICE_LIFE_CODE = 3

const currentDate = moment().format(DATE_FORMAT_RFC)

const FormData: React.FC<Props> = ({
  initialValues,
  masterData,
  formMode,
  isImportMode = false,
  isTemporaryData,
  onSaveTemp,
  onHandleReloadMasterOptions,
  showRequiredError,
  onSubmit,
  confirming,
}) => {
  const dispatch = useDispatch<any>()
  const modal: any = useModalHook()
  const { isEnable: isEnableForFixedAssetReduction } = useCheckFlag(FeatureFlagNames.FixedAssetReduction)
  const isNewMode = formMode === FORM_MODES.NEW
  const isEditMode = formMode === FORM_MODES.EDIT
  const isCopyMode = formMode === FORM_MODES.COPY
  const isCorrectionMode = formMode === FORM_MODES.CORRECTION

  useFormValidation()
  useSubmitOnError(onSubmit)
  const { values, errors, setFieldValue, dirty, resetForm } = useFormikContext()
  const [resultSelectedAssetCategory, getSelectedAssetCategory] = useResource(assetCategoryApi.getAssetCategory)
  const [{ data: responseGetMemorandumValue }, calculateMemorandumValue] = useResource(calculateMemorandumValueApi)

  const tags = defaultTo(
    tagListSlice.useSlice(state => state?.data?.tags),
    []
  )
  const units = defaultTo(
    unitsSlice.useSlice(state => state?.data?.units),
    []
  )
  const assetCodeAutoGenerateSettingListData = useSelector(selectAssetCodeAutoGenerateSettingListData, isEqual)
  const terms = useSelector(selectTermsData, isEqual)
  const exciseSettingsByDateData = useSelector(getExciseSettingsByDateList, isEqual)
  const selectedImportAsset = useSelector(selectAssetImportItemData, isEqual)
  const ledgerSettingList = useSelector(selectLedgerSettingList, isEqual)
  const { data: permissions } = useSelector(selectUserPermission, isEqual)

  const [debounceValues] = useDebounce(values, AUTO_SAVE_DELAY_TIME)
  const [calculatedExciseValueResponse, getCalculatedExciseValue] = useResource(exciseApi.getExciseVatInfo)

  const [exciseSettingDate, setExciseSettingDate] = useState<string>(currentDate)
  const [isTheFirstLoading, setIsTheFirstLoading] = useState<boolean>(false)
  const [assetCategoryList, setAssetCategoryList] = useState<IAssetCategory[]>()
  const [assetType, setAssetType] = useState<number | undefined>()
  const [isQuantityChanged, setQuantityChanged] = useState<boolean>(false)

  const { data, isLoading: isExciseSettingLoading } = exciseSettingsByDateData || []

  const hasTransition = get(values, 'has_transition')
  const hasExamption = get(values, 'has_exemption')
  const acquiredAt = get(values, 'acquired_at')
  const usageStartedAt = get(values, 'usage_started_at')
  const exciseId = get(values, 'excise_id')
  const acquisitionCostInput = get(values, 'acquisition_cost_input')
  const acquisitionExcludedInput = get(values, 'acquisition_cost_excluded_excise')
  const manualValue = get(values, 'manual_excise_value')
  const transitionAt = get(values, 'transition_at')
  const calculatedExciseValue = get(values, 'calculated_excise_value')
  const depreciablePropertyTaxValue = get(values, ['asset_type_for_depreciable_property_tax', 'code'])
  const assetTypeForCorporateTaxCode = get(values, ['asset_type_for_corporate_tax', 'code'])
  const assetDetailForCorporateTaxValue = get(values, 'asset_detail_for_corporate_tax')
  const assetStructureForCorporateTax = get(values, 'asset_structure_for_corporate_tax')
  const assetCategoryId = get(values, 'asset_category_id')?.value
  const ledgerList: Array<AssetLedger> = get(values, 'fixed_asset_ledgers')
  const unitId = get(values, 'unit_id')
  const quantity = get(values, 'quantity')
  const reductionAmount = get(values, 'reduction_amount')
  const netAcquisitionCost = get(values, 'net_acquisition_cost')
  const memorandumValueFieldOfFirstItem = getIn(values, 'fixed_asset_ledgers[0].memorandum_value')
  const linkMemorandumValueQuantity = units?.find(unit => unit.id === unitId)?.link_memorandum_value_quantity || false

  const isInputTaxWhenRegister = data && data.input_tax_when_register_fixed_asset === EXECUTE_VAT.EXECUTE
  const isTaxMethodExcluded = data && data.tax_method === EXCISE_TAX_METHOD.EXCLUDED_TAX
  const isTaxInputFormatExternalTax = data && data.tax_input_format === EXCISE_TAX_INPUT_FORMAT.EXTERNAL_TAX
  const isCanEditExciseInputByManual = data && data.can_edit_tax === EXCISE_CAN_EDIT_TAX.YES
  const isExternalTax = isTaxMethodExcluded && isInputTaxWhenRegister && isTaxInputFormatExternalTax
  const isInternalTax = isTaxMethodExcluded && isInputTaxWhenRegister && !isTaxInputFormatExternalTax
  const isManualExciseValue = !!manualValue || manualValue === 0
  const [debouncedNewAcquisitionCost] = useDebounce(acquisitionCostInput, 500)

  const [bookValueReferUpdatedManually, setBookValueReferUpdatedManually] = useBoolean(false)
  const acquisitionCostReference = useAcquisitionCostReference(
    isTaxMethodExcluded,
    acquisitionCostInput,
    acquisitionExcludedInput,
    data?.tax_input_format
  )
  const bookValueReference = useBookValueReference(acquisitionCostReference, netAcquisitionCost)
  useSubsidyAmount(acquisitionCostReference, bookValueReferUpdatedManually)

  const validAtFilter = useMemo(() => {
    return transitionAt || acquiredAt
  }, [acquiredAt, transitionAt])

  useEffect(() => {
    assetCategoryId && getSelectedAssetCategory(assetCategoryId)
  }, [assetCategoryId]) // eslint-disable-line

  useEffect(() => {
    const selectedAssetCategory = resultSelectedAssetCategory.data
    if (selectedAssetCategory) {
      const dMethodCodeOfFirstItem = get(values, 'fixed_asset_ledgers[0].depreciation_method_code')
      const depreciationMethodCode = get(selectedAssetCategory, 'depreciation_method.code')
      const serviceLifeCode = get(selectedAssetCategory, 'service_life')
      const exciseId = initialValues?.excise_id || get(selectedAssetCategory, ['excise_id'])

      if (!exciseId) {
        setFieldValue('calculated_excise_value', 0)
        setFieldValue('manual_excise_value', 0)
        setFieldValue('acquisition_cost', acquisitionCostInput)
        setFieldValue('acquisition_cost_excluded_excise', acquisitionCostInput)
      } else {
        setFieldValue('acquisition_cost', initialValues?.acquisition_cost)
        setFieldValue('acquisition_cost_excluded_excise', initialValues?.acquisition_cost_excluded_excise)
      }

      setFieldValue('excise_id', exciseId)
      setFieldValue(
        'asset_type_for_corporate_tax_code',
        assetTypeForCorporateTaxCode || get(selectedAssetCategory, ['asset_type_for_corporate_tax', 'code'])
      )
      setFieldValue(
        'asset_type_for_depreciable_property_tax_code',
        depreciablePropertyTaxValue ||
          (isNewMode ? get(selectedAssetCategory, ['asset_type_for_depreciable_property_tax', 'code']) : null)
      )
      setFieldValue(
        'asset_structure_for_corporate_tax',
        assetStructureForCorporateTax || get(selectedAssetCategory, 'asset_structure_for_corporate_tax')
      )
      setFieldValue(
        'asset_detail_for_corporate_tax',
        assetDetailForCorporateTaxValue || get(selectedAssetCategory, 'asset_detail_for_corporate_tax')
      )

      if (!dMethodCodeOfFirstItem) {
        const isBulkEqualInstallment = depreciationMethodCode === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD
        setFieldValue('fixed_asset_ledgers[0].depreciation_method_code', depreciationMethodCode)
        const currentServiceLifeCode = isBulkEqualInstallment ? DEFAULT_SERVICE_LIFE_CODE : serviceLifeCode
        setFieldValue('fixed_asset_ledgers[0].service_life', currentServiceLifeCode)
      }
    }
  }, [resultSelectedAssetCategory.data]) // eslint-disable-line

  useEffect(() => {
    if (dirty && debounceValues) {
      if (isNewMode || isCopyMode) onSaveTemp && onSaveTemp(TempDataTypes.CREATE_ASSET, debounceValues)
      else if (isEditMode) onSaveTemp && onSaveTemp(TempDataTypes.EDIT_ASSET, debounceValues, get(values, 'biid'))
    }
  }, [debounceValues]) // eslint-disable-line

  useEffect(() => {
    let initialExciseSettingDate

    if (initialValues) {
      const initialAcquiredAt = initialValues?.acquired_at
      const transitionAt = initialValues?.transition_at

      setFieldValue(
        'acquired_at',
        isCopyMode || isEditMode || isImportMode || isCorrectionMode ? initialAcquiredAt : currentDate
      )
      acquiredAt !== initialAcquiredAt && setFieldValue('acquired_at', acquiredAt)
      if (initialAcquiredAt) {
        setExciseSettingDate(transitionAt || initialAcquiredAt)
      }

      initialExciseSettingDate = transitionAt || initialAcquiredAt
      initialExciseSettingDate && setExciseSettingDate(initialExciseSettingDate)
      setIsTheFirstLoading(true)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (isTemporaryData && initialValues) {
      const initialAcquiredAt = initialValues?.acquired_at || currentDate
      const transitionAt = initialValues?.transition_at
      const isTransitionAt = !!transitionAt

      resetForm({ values: initialValues })
      const initialExciseSettingDate = ifElse(isTransitionAt, transitionAt, initialAcquiredAt)
      initialExciseSettingDate && setExciseSettingDate(initialExciseSettingDate)
      setFieldValue('acquired_at', initialAcquiredAt)
    }
  }, [isTemporaryData]) // eslint-disable-line

  useEffect(() => {
    exciseSettingDate && dispatch(fetchExciseSettingsByDate(exciseSettingDate))
  }, [exciseSettingDate, dispatch])

  /**
   * For the excise setting is: Excluded - no input
   * The excise field is not displayed on UI. The excise_id should be reset value
   * 2 field values below should be equal acquisition cost which user input
   * acquisition_cost_excluded_excise, acquisition_cost
   */
  useEffect(() => {
    if (!isInputTaxWhenRegister) {
      setFieldValue('excise_id', null)
      setFieldValue('acquisition_cost_excluded_excise', acquisitionCostInput)
      setFieldValue('acquisition_cost', acquisitionCostInput)
    }
  }, [isInputTaxWhenRegister]) // eslint-disable-line

  useEffect(() => {
    if (
      manualValue === null &&
      debouncedNewAcquisitionCost &&
      exciseSettingDate &&
      exciseId &&
      isInputTaxWhenRegister &&
      isTaxMethodExcluded &&
      !isExciseSettingLoading
    ) {
      getCalculatedExciseValue(exciseSettingDate, exciseId, debouncedNewAcquisitionCost)
    }
  }, [
    exciseId,
    exciseSettingDate,
    isInputTaxWhenRegister,
    isTaxMethodExcluded,
    debouncedNewAcquisitionCost,
    manualValue,
  ]) // eslint-disable-line

  useEffect(() => {
    /**
     * Condition handles rendering correctly acquisition cost value after importing
     */
    if (initialValues) {
      const acquisitionCostValue = initialValues?.acquisition_cost
      const acquisitionCostExcludedExcise = initialValues?.acquisition_cost_excluded_excise

      if ((isImportMode || isTheFirstLoading) && isTaxMethodExcluded !== null) {
        if (isTaxMethodExcluded === false) {
          setFieldValue('acquisition_cost_input', acquisitionCostValue)
        } else if (isInternalTax) {
          setFieldValue('acquisition_cost_input', acquisitionCostValue)
        } else if (isExternalTax) {
          setFieldValue('acquisition_cost_input', acquisitionCostExcludedExcise)
        } else {
          setFieldValue('acquisition_cost_input', acquisitionCostValue)
        }
      }
    }
  }, [isExternalTax, isInternalTax, isImportMode, isTheFirstLoading]) // eslint-disable-line

  useEffect(() => {
    const errorResponse = get(calculatedExciseValueResponse, 'error[0]')

    if (calculatedExciseValueResponse?.status === 400 && errorResponse?.code === ERR_EXCISE_NOT_VALID_AT_TIME) {
      dispatch(setAppMessage({ type: 'failure', content: errorResponse?.detail }))
      return
    }

    if (calculatedExciseValueResponse?.data) {
      const { included_excise_value, excluded_excise_value, excise_value } = calculatedExciseValueResponse.data || {}

      setFieldValue('acquisition_cost', included_excise_value)
      setFieldValue(
        'acquisition_cost_excluded_excise',
        isInputTaxWhenRegister ? excluded_excise_value : acquisitionCostInput
      )
      setFieldValue('calculated_excise_value', excise_value)

      if (manualValue && (isCopyMode || isEditMode)) {
        if (isInternalTax) {
          setFieldValue('acquisition_cost_input', included_excise_value)
          setFieldValue('acquisition_cost_excluded_excise', included_excise_value - manualValue)
        }

        if (isExternalTax) {
          setFieldValue('acquisition_cost_input', excluded_excise_value)
          setFieldValue('acquisition_cost', excluded_excise_value + manualValue)
        }
      }
    }
  }, [calculatedExciseValueResponse]) // eslint-disable-line

  useEffect(() => {
    const assetCategory = assetCategoryList?.find(
      (assetCategory: IAssetCategory) => assetCategory.id === assetCategoryId
    )
    setAssetType(ifElse(assetCategory, assetCategory?.asset_type, 0))
  }, [assetCategoryList, assetCategoryId])

  useEffect(() => {
    const memorandumValueMap = new Map(
      responseGetMemorandumValue?.ledgers?.map(({ id, memorandum_value }: { id: number; memorandum_value: number }) => [
        id,
        memorandum_value,
      ])
    )

    const getMemorandumValue = (ledger: AssetLedger) => {
      if (ledger?.is_copied) return memorandumValueFieldOfFirstItem
      if (assetType === ASSET_TYPE.INTANGIBLE && linkMemorandumValueQuantity) return DEFAULT_MEMORANDUM_VALUE.INTANGIBLE
      if (responseGetMemorandumValue && linkMemorandumValueQuantity)
        return memorandumValueMap.get(ledger.ledger_setting_id) || quantity
      if (
        selectedImportAsset ||
        isCopyMode ||
        isEditMode ||
        isCorrectionMode ||
        ledger?.is_memorandum_value_entered ||
        (!linkMemorandumValueQuantity &&
          ledger?.memorandum_value !== null &&
          ![DEFAULT_MEMORANDUM_VALUE.INTANGIBLE, DEFAULT_MEMORANDUM_VALUE.TANGIBLE].includes(ledger?.memorandum_value))
      )
        return ledger?.memorandum_value
      if (quantity >= 0 && linkMemorandumValueQuantity) return quantity
      return assetType === ASSET_TYPE.TANGIBLE ? DEFAULT_MEMORANDUM_VALUE.TANGIBLE : DEFAULT_MEMORANDUM_VALUE.INTANGIBLE
    }
    const ledgerMergeSetting = ledgerList?.map(faLedger => ({
      ...faLedger,
      ...ledgerSettingList?.find(ledgerSetting => ledgerSetting?.id === faLedger?.ledger_setting_id),
    }))

    const canCopyFromAccountant = () => {
      // Prepare data by merge fixed_asset_ledgers with ledger_settings data
      const accountantDmethod =
        ledgerMergeSetting?.find(ledger => ledger?.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING)
          ?.depreciation_method_code || 0
      const taxDmethod =
        ledgerMergeSetting?.find(ledger => ledger?.ledger_purpose === LEDGER_PURPOSE.TAX)?.depreciation_method_code || 0
      // Check 2 Dmethod belong to 5 Dmethods that allow input memorandum value
      return checkShowMemorandumValue(accountantDmethod) && checkShowMemorandumValue(taxDmethod)
    }

    const setMemorandumValue = (dMethodByEachLedger: number, ledgerindex: number = 0) => {
      /**
       * If the ledger's depreciation method is not in 1 of the 5 methods [10, 11, 20, 21, 22] ,
       * then set memorandum_value = null
       */
      const memorandumField = `fixed_asset_ledgers[${ledgerindex}].memorandum_value`
      if (dMethodByEachLedger !== null && !checkShowMemorandumValue(dMethodByEachLedger)) {
        setFieldValue(
          memorandumField,
          dMethodByEachLedger === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD ? 0 : null
        )
      } else if (ledgerMergeSetting[ledgerindex]?.ledger_purpose === LEDGER_PURPOSE.TAX && canCopyFromAccountant()) {
        // Handle copying memorandum value of accountant ledger to tax ledger
        const accountantLedgerIndex = ledgerSettingList?.findIndex(
          ledgerSetting => ledgerSetting?.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING
        )
        const accountantLedgerMemorandum = getIn(
          values,
          `fixed_asset_ledgers[${accountantLedgerIndex}].memorandum_value`,
          0
        )
        setFieldValue(memorandumField, accountantLedgerMemorandum)
      } else {
        setFieldValue(memorandumField, getMemorandumValue(ledgerMergeSetting[ledgerindex]))
      }
    }

    ledgerMergeSetting?.forEach((_, index) => {
      const dMethodByEachLedger = get(ledgerMergeSetting, `[${index}].depreciation_method_code`)
      setMemorandumValue(dMethodByEachLedger, index)
    })
  }, [
    assetType,
    quantity,
    ledgerList?.map((item: any) => item?.depreciation_method_code).join(''),
    responseGetMemorandumValue,
    linkMemorandumValueQuantity,
    ledgerList?.map((item: any) => item?.memorandum_value).join(''),
  ]) // eslint-disable-line

  const isLedgersSelectedDMethod = ledgerList?.every(
    (ledger: AssetLedger) => typeof ledger.depreciation_method_code === 'number'
  )
  useEffect(() => {
    if (
      isLedgersSelectedDMethod &&
      assetType &&
      unitId &&
      linkMemorandumValueQuantity &&
      Number(quantity) >= 0 &&
      (isQuantityChanged || isEditMode || isCopyMode || isImportMode || isCorrectionMode)
    ) {
      // Only take ledgers with depreciation method of [10, 11, 20, 21, 22]
      const ledgers = ledgerList.reduce((filteredLedger: any, ledger: AssetLedger) => {
        const { ledger_setting_id, depreciation_method_code } = ledger
        if (depreciation_method_code !== null && checkShowMemorandumValue(depreciation_method_code)) {
          filteredLedger.push({
            id: ledger_setting_id,
            depreciation_method: depreciation_method_code,
          })
        }
        return filteredLedger
      }, [])
      const payload = { ledgers, quantity: parseInt(quantity, 10), unit_id: unitId, asset_type: assetType }
      calculateMemorandumValue(payload)
    }
  }, [assetType, unitId, quantity, isLedgersSelectedDMethod, isQuantityChanged, linkMemorandumValueQuantity]) // eslint-disable-line

  const getAssetCategories = async (filters: any) => {
    const response = await assetCategoryApi.search(filters)
    setAssetCategoryList(get(response, 'data.data'))

    return response
  }

  /**
   * Handle reset code and branch code when change acquired at value
   */
  const currentSetting = useMemo(() => {
    const acquiredAt = get(values, 'acquired_at')

    if (!assetCodeAutoGenerateSettingListData || !acquiredAt) return null
    return assetCodeAutoGenerateSettingListData.find(
      setting =>
        checkAcquiredAtBelongToAssetCodeAutoGenerate(setting, acquiredAt) ||
        checkAcquiredAtBelongToAssetCodeAutoGenerate(setting, transitionAt)
    )
  }, [assetCodeAutoGenerateSettingListData, values, setFieldValue, transitionAt])

  useEffect(() => {
    if (currentSetting?.generating_method === ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.AUTO_NUMBER) {
      setFieldValue(codeField.text, '')
      setFieldValue(branchCodeField.text, '')
    } else if (currentSetting?.generating_method === ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.AUTO_NUMBER_BRANCH) {
      setFieldValue(branchCodeField.text, '')
    }
  }, [currentSetting])

  /**
   * handleAcquisitionChanged: Function handle acquisition cost value
   * included excise and excluded excise value
   * @param newValue: number
   */
  const handleAcquisitionChanged = (newValue: any) => {
    if (!calculatedExciseValue || newValue === 0) {
      setFieldValue('manual_excise_value', null)
      setFieldValue('acquisition_cost', newValue)
      setFieldValue('acquisition_cost_excluded_excise', newValue)
      setFieldValue('calculated_excise_value', 0)
    }

    !isInputTaxWhenRegister && setFieldValue('acquisition_cost_excluded_excise', newValue)
    setFieldValue('manual_excise_value', null)
    ;(!isTaxMethodExcluded || !isInputTaxWhenRegister) && setFieldValue('acquisition_cost', newValue)
    setBookValueReferUpdatedManually()
  }

  /**
   * handleOnChangeManualExciseValue: Function handle onChange manual Value
   * @param newValue: number
   */
  const handleOnChangeManualExciseValue = (newValue: number) => {
    if (newValue || newValue === 0) {
      if (isExternalTax) {
        const includedAcquisitionCost = acquisitionCostInput + newValue
        setFieldValue('acquisition_cost', includedAcquisitionCost)
      } else if (isInternalTax) {
        const excludedAcquisitionCost = acquisitionCostInput - newValue
        setFieldValue('acquisition_cost_excluded_excise', excludedAcquisitionCost)
      }
    }
    setFieldValue('manual_excise_value', newValue)
    setBookValueReferUpdatedManually()
  }

  const handleAfterChangedAcquiredAt = (acquiredAt: Moment | null) => {
    if (acquiredAt) {
      const newAcquiredAtValue = acquiredAt.format(DATE_FORMAT_RFC)

      if (acquiredAt > moment()) {
        message.warning(t('msg_acquired_at_future'))
      }

      const currentExciseSetting = {
        inputTaxWhenRegisterFixedAsset: data && data.input_tax_when_register_fixed_asset,
        taxMethod: data && data.tax_method,
        taxInputMethod: data && data.tax_input_format,
      }
      const preAcquiredAt = get(values, 'acquired_at')

      if (!transitionAt) {
        setFieldValue('area_biid', null)
        setFieldValue('area', null)
        setFieldValue('department_biid', null)
        setFieldValue('manual_excise_value', null)
        fetchNewExciseSetting(newAcquiredAtValue, dispatch, fetchExciseSettingsByDate)
          .then((newExciseSetting: any) => {
            setExciseSettingDate(newAcquiredAtValue)

            if (newExciseSetting?.tax_method === EXCISE_TAX_METHOD.INCLUDED_TAX) {
              setFieldValue('acquisition_cost_excluded_excise', null)
              setFieldValue('acquisition_cost', acquisitionCostInput)
            }

            if (!!data && isDifferentExciseSetting(currentExciseSetting, newExciseSetting)) {
              return modal.confirm({
                title: t('notify_change_excise_setting_title'),
                message: t('notify_change_excise_setting_message'),
                type: CONFIRM_TYPES.CONFIRM,
              })
            }
          })
          .then((isOK: boolean) => {
            /**
             * This case accept only false value (it won't accept undefined and null value)
             */
            if (isOK === false) {
              setFieldValue('acquired_at', preAcquiredAt)
              setExciseSettingDate(preAcquiredAt)
            }
          })
      }
    }
    setIsTheFirstLoading(false)
    setBookValueReferUpdatedManually()
  }

  const handleAfterChangedTransitionAt = (transitionAtValue: any) => {
    if (transitionAtValue) {
      const newTransitionAtValue = transitionAtValue.format(DATE_FORMAT_RFC)

      const currentExciseSetting = {
        inputTaxWhenRegisterFixedAsset: data && data.input_tax_when_register_fixed_asset,
        taxMethod: data && data.tax_method,
        taxInputMethod: data && data.tax_input_format,
      }
      const preTransitionAt = get(values, 'transition_at')

      fetchNewExciseSetting(newTransitionAtValue, dispatch, fetchExciseSettingsByDate)
        .then((newExciseSetting: any) => {
          if (!!data && isDifferentExciseSetting(currentExciseSetting, newExciseSetting)) {
            return modal.confirm({
              title: t('notify_change_excise_setting_title'),
              message: t('notify_change_excise_setting_message'),
              type: CONFIRM_TYPES.CONFIRM,
            })
          }
          setTransitionAtValue(initialValues, setFieldValue, newTransitionAtValue)
          setExciseSettingDate(newTransitionAtValue)
        })
        .then((isOK: boolean) => {
          if (isOK === false) {
            setTransitionAtValue(initialValues, setFieldValue, preTransitionAt)
            setExciseSettingDate(preTransitionAt)
          }
        })
      setExciseSettingDate(newTransitionAtValue)
      setFieldValue('area_biid', null)
      setFieldValue('area', null)
      setFieldValue('department_biid', null)
    }
    setIsTheFirstLoading(false)
    setBookValueReferUpdatedManually()
  }

  const handleAfterCheckSameWithAcquiredAt = () => {
    setFieldValue('usage_started_at', get(values, 'acquired_at'))
  }

  const handleAreaChanged = async (selectedValue: any, selectedArea: any) => {
    setFieldValue('area', selectedArea || null)
  }

  const handleDepartmentChanged = async (selectedValue: any, selectedDepartment: any) => {
    setFieldValue('department', selectedDepartment || null)
  }

  const handleAfterCheckExemption = (value: boolean) => {
    if (value) {
      setFieldValue('exemption_numerator', 1)
      setFieldValue('exemption_denominator', 1)
    } else {
      setFieldValue('exemption_numerator', 0)
      setFieldValue('exemption_denominator', 0)
    }
  }

  const handleChangeExciseSelectValue = () => {
    setFieldValue('manual_excise_value', null)
    setBookValueReferUpdatedManually()
  }

  const handleResetManualExciseValue = () => {
    const calculatedExciseValueResponseValue = calculatedExciseValueResponse?.data
    if (manualValue !== null) {
      setFieldValue('manual_excise_value', null)

      if (calculatedExciseValueResponseValue) {
        setFieldValue('calculated_excise_value', calculatedExciseValueResponseValue?.excise_value)
        setFieldValue('acquisition_cost', calculatedExciseValueResponseValue?.included_excise_value)
        setFieldValue('acquisition_cost_excluded_excise', calculatedExciseValueResponseValue?.excluded_excise_value)
      } else {
        const calculateExciseValue = initialValues?.calculated_excise_value

        setFieldValue('calculated_excise_value', initialValues?.calculated_excise_value)

        if (isExternalTax) {
          setFieldValue('acquisition_cost_excluded_excise', acquisitionCostInput)

          calculateExciseValue && setFieldValue('acquisition_cost', acquisitionCostInput + calculateExciseValue)
        } else if (isInternalTax) {
          setFieldValue('acquisition_cost', acquisitionCostInput)

          if (calculateExciseValue) {
            setFieldValue('acquisition_cost_excluded_excise', acquisitionCostInput - calculateExciseValue)
          }
        }
      }
    }
    setBookValueReferUpdatedManually()
  }

  const handleChangeTransitionCheckBox = (value: any) => {
    if (!value) {
      setFieldValue('area_biid', null)
      setFieldValue('area', null)
      setFieldValue('department_biid', null)
      setFieldValue('transition_at', null)
      setExciseSettingDate(acquiredAt)
      setIsTheFirstLoading(false)
    }
    setBookValueReferUpdatedManually()
  }

  const handleQuantityChanged = (isChanged: boolean) => {
    setQuantityChanged(isChanged)
  }

  const handleAssetCategoryChange = (selectedValue: any, assetCategoryInfo: any) => {
    setFieldValue('asset_type', assetCategoryInfo?.asset_type)

    for (let i = 0; i < ledgerList?.length; i++) {
      setFieldValue(`fixed_asset_ledgers[${i}].is_memorandum_value_entered`, false)
    }
  }

  const renderLedgers = (fixed_asset_ledgers: AssetLedger[], isEditMode: boolean) => {
    if (!fixed_asset_ledgers) return []
    const isDisabledTransitionAt = typeof get(values, 'fixed_asset_ledgers[0].transition_at') === 'string'

    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'wrap',
          paddingTop: 20,
          paddingBottom: 10,
          justifyContent: 'space-between',
        }}
      >
        {fixed_asset_ledgers.map((item, index) => (
          <DepreciationLedge
            key={`${item.ledger_setting_id}`}
            ledgerName={isEditMode || isCopyMode ? item?.ledger_setting?.name || '' : item.name}
            ledgerIndex={index}
            masterData={masterData}
            disabled={isEditMode}
            isTransitionDayIsFirstDayOfTerm={isTransitionDayIsFirstDayOfTerm()}
            onAfterChangedTransitionAt={handleAfterChangedTransitionAt}
            isEditMode={isEditMode}
            showRequiredError={showRequiredError}
            isDisabledTransitionAt={index === 0 ? false : isDisabledTransitionAt}
            formMode={formMode}
            isImportMode={isImportMode}
            bookValueRefer={bookValueReference}
            bookValueReferUpdatedManually={bookValueReferUpdatedManually}
            acquisitionCostValueRefer={acquisitionCostReference}
          />
        ))}
      </div>
    )
  }

  const renderAssetCode = useCallback(() => {
    if (currentSetting?.generating_method === ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.AUTO_NUMBER) {
      return (
        <AssetCodeWrapper>
          <div style={{ width: 120 }}>
            <Tooltip content={t('auto_generate_code_tooltip')} placement="top">
              <Input
                name={codeField.text}
                type="code"
                maxLength={codeField.maxLength}
                isShowError={false}
                disabled
                value=""
              />
            </Tooltip>
          </div>
          <div style={{ width: 76 }}>
            <Tooltip content={t('auto_generate_code_tooltip')} placement="top">
              <Input
                name={branchCodeField.text}
                type="code"
                maxLength={branchCodeField.maxLength}
                style={{ marginLeft: 24 }}
                isShowError={false}
                disabled
                value=""
              />
            </Tooltip>
          </div>
        </AssetCodeWrapper>
      )
    }
    if (currentSetting?.generating_method === ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.AUTO_NUMBER_BRANCH) {
      return (
        <AssetCodeWrapper>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <RegexInput
              name={codeField.text}
              type="code"
              maxLength={codeField.maxLength}
              style={{ width: 120 }}
              isShowError={false}
              disabled={isEditMode}
              placeholder={ifElse(!isEditMode, t('placeholder.under_15'), '')}
              className={classNames({ error: showRequiredError && !get(values, codeField.text) })}
            />
            {showRequiredError && !get(values, codeField.text) && <Error>{t('code_required_error')}</Error>}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Tooltip content={t('auto_generate_code_tooltip')} placement="top">
              <Input
                name={branchCodeField.text}
                value=""
                type="branch_code"
                maxLength={branchCodeField.maxLength}
                style={{ width: 76, marginLeft: 24 }}
                isShowError={false}
                disabled
              />
            </Tooltip>
          </div>
        </AssetCodeWrapper>
      )
    }

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <RegexInput
            name={codeField.text}
            type="code"
            maxLength={codeField.maxLength}
            style={{ width: 120 }}
            isShowError={false}
            disabled={isEditMode}
            placeholder={!isEditMode ? t('placeholder.under_15') : ''}
            className={classNames({ error: showRequiredError && !get(values, codeField.text) })}
          />
          {showRequiredError && !get(values, 'code') && <Error>{t('code_required_error')}</Error>}
        </div>
        <div>
          <RegexInput
            name={branchCodeField.text}
            type="branch_code"
            maxLength={branchCodeField.maxLength}
            style={{ width: 76, marginLeft: 24 }}
            placeholder={!isEditMode ? t('placeholder.under_3') : ''}
            isShowError={false}
            disabled={isEditMode}
            className={classNames({ error: showRequiredError && !get(values, branchCodeField.text) })}
          />
          {showRequiredError && !get(values, branchCodeField.text) && (
            <Error style={{ marginLeft: 24 }}>
              <label>{t('branch_code_required_error')}</label>
            </Error>
          )}
        </div>
      </div>
    )
  }, [currentSetting?.generating_method, isEditMode, showRequiredError, values])

  const isDisabledSubsidyAmount = useMemo(
    () => isEditMode || (!hasTransition && !moment(acquiredAt).isSame(moment(usageStartedAt))),
    [isEditMode, hasTransition, acquiredAt, usageStartedAt]
  )

  const renderAcquisitionSection = () => {
    let label = t('acquisition_cost')
    if (isExternalTax) {
      label = t('acquisition_cost_excluded_excise')
    } else if (isInternalTax) {
      label = t('acquisition_cost_included_excise')
    }

    return (
      <>
        <Item label={label} required>
          <MoneyInput
            name="acquisition_cost_input"
            disabled={isEditMode}
            onChange={handleAcquisitionChanged}
            style={{ textAlign: 'right' }}
            className={classNames({ error: showRequiredError && !get(values, 'acquisition_cost_input') })}
          />
          {showRequiredError && !get(values, 'acquisition_cost_input') && (
            <Error>{t('acquisition_cost_required_error')}</Error>
          )}
        </Item>
        {/* EXCISE FEATURE */}
        {isInputTaxWhenRegister && (
          <Item label={t('excise')} required>
            <ExciseSelect
              style={{
                width: '236px',
              }}
              name="excise_id"
              isDisabled={!acquiredAt || isEditMode || resultSelectedAssetCategory.isLoading}
              afterChanged={handleChangeExciseSelectValue}
              className={classNames({ error: showRequiredError && !get(values, 'excise_id') })}
            />
            {showRequiredError && !get(values, 'excise_id') && <Error>{t('excise_required_error')}</Error>}
            <Error>
              <label>{get(errors, 'excise_id')}</label>
            </Error>
          </Item>
        )}
        {(isExternalTax || isInternalTax) && (
          <>
            <Item label={t('excise_amount')}>
              <UnderlineMoneyInput
                style={{ textAlign: 'right' }}
                editable={isCanEditExciseInputByManual && !isEditMode}
                maxValue={isInternalTax ? acquisitionCostInput : null}
                name={
                  manualValue !== null && manualValue !== undefined ? 'manual_excise_value' : 'calculated_excise_value'
                }
                placeholder={`${t('excise_amount')} ${t('included_tax')}`}
                onChange={handleOnChangeManualExciseValue}
                isResetManualValue={isCanEditExciseInputByManual && !isEditMode && isManualExciseValue}
                onHandleClickOnReset={handleResetManualExciseValue}
              />
            </Item>
            <Item label={isExternalTax ? t('acquisition_cost_included_excise') : t('acquisition_cost_excluded_excise')}>
              <UnderlineMoneyInput
                style={{ textAlign: 'right' }}
                name={isExternalTax ? 'acquisition_cost' : 'acquisition_cost_excluded_excise'}
                placeholder={`${t('acquisition_cost_included_excise')} ${t('included_tax')}`}
              />
            </Item>
          </>
        )}
        <If condition={isEnableForFixedAssetReduction}>
          <Item
            label={
              <ItemLabelTooltip label={t('subsidy_amount')} tooltip={parser(t('subsidy_amount_tooltip_message'))} />
            }
          >
            <MoneyInput
              name="subsidy_amount"
              disabled={isDisabledSubsidyAmount}
              onChange={setBookValueReferUpdatedManually}
              defaultValue={undefined}
            />
          </Item>
        </If>
        <If condition={isEnableForFixedAssetReduction}>
          <Item label={t('reduction_amount')}>
            <UnderlineMoneyInput
              name="reduction_amount"
              editable={!!hasTransition}
              placeholder={t('reduction_amount_placeholder')}
              style={{ textAlign: ifElse(!isNumberHaveValue(reductionAmount), 'left', 'right') }}
            />
          </Item>
          <Item label={t('net_acquisition_cost')}>
            <UnderlineMoneyInput
              style={{ textAlign: ifElse(!isNumberHaveValue(netAcquisitionCost), 'left', 'right') }}
              name="net_acquisition_cost"
              placeholder={t('net_acquisition_cost_placeholder')}
            />
          </Item>
        </If>
      </>
    )
  }

  const isTransitionDayIsFirstDayOfTerm = useCallback(() => {
    return isFirstDayOfTerm(terms, transitionAt)
  }, [transitionAt, terms])

  const isUseAutoNumberGenerate =
    currentSetting?.generating_method === ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.AUTO_NUMBER

  const getTagOptions = () => {
    return tags?.map((tag: PresenterOneTagResponse) => ({
      label: tag.name,
      value: tag.id,
    })) as OptionsType
  }

  return (
    <>
      <UnborderCollapse title={t('title_basic_info')} collapsable={false}>
        <LeftGroup>
          <Item label={t('name')} required>
            <AutoKanaInput
              name={nameField.text}
              className={classNames({ error: showRequiredError && !get(values, nameField.text) })}
              kanaField="name_kana"
              placeholder={t('placeholder.under_50')}
              maxLength={nameField.maxLength}
            />
            {showRequiredError && !get(values, 'name') && <Error>{t('name_required_error')}</Error>}
          </Item>
          <Item label={t('name_kana')}>
            <Input name="name_kana" placeholder={t('placeholder.under_50')} maxLength={50} />
          </Item>
          <Item label={t('asset_code')} required={!isUseAutoNumberGenerate}>
            {renderAssetCode()}
            <HorizontalLine
              style={{
                width: 8,
                backgroundColor: '#333333',
                position: 'absolute',
                top: 15,
                left: 128,
              }}
            />
            <Error>
              <label>{get(errors, codeField.text)}</label>
              <label>{get(errors, branchCodeField.text)}</label>
            </Error>
          </Item>
          <Item label={t('acquired_at')} required>
            <DatePicker
              name="acquired_at"
              afterChanged={handleAfterChangedAcquiredAt}
              maxDateField="usage_started_at"
              allowClear={false}
              disabled={isEditMode}
            />
          </Item>
          <Item label={t('used_at')}>
            <DatePicker name="usage_started_at" minDateField="acquired_at" disabled={isEditMode} />
            {(isNewMode || isCopyMode || isCorrectionMode) && (
              <div style={{ display: 'flex', marginTop: 3 }} onClick={handleAfterCheckSameWithAcquiredAt}>
                <CopyIcon style={{ marginTop: 4, cursor: 'pointer' }} />
                <LinkLabel style={{ marginLeft: 3 }}>{t('same_as_acquired_at')}</LinkLabel>
              </div>
            )}
          </Item>
          <Item label={t('acquisition_category')} required>
            <AutoCompleteSelect
              name="acquire_category_code"
              items={masterData.acquire_category}
              valueField="code"
              textField="name_jp"
              allowClear={false}
              className={classNames({ error: showRequiredError && !get(values, 'acquire_category_code') })}
            />
            {showRequiredError && !get(values, 'acquire_category_code') && (
              <Error>{t('acquisition_category_required_error')}</Error>
            )}
          </Item>
          <Item label={t('asset_category')} required>
            <FormikInfiniteScrollSelect
              name="asset_category_id"
              valueField="id"
              textField="name"
              disabled={isEditMode}
              onChange={(selectedValue, assetCategoryInfo) =>
                handleAssetCategoryChange(selectedValue, assetCategoryInfo)
              }
              executeInfiniteScroll={({ textSearch, pageSize, page }) => {
                return getAssetCategories({ filters: { name: textSearch }, pageSize, page })
              }}
              renderFooter={({ refetch, isLoading, isRefetching, isFetching }) => {
                const isExecuting = isRefetching || isLoading || isFetching
                return (
                  <RedirectToMasterPageLink
                    permissions={handlePermissionByFeatureId(FEATURES_ID.MASTER_ASSET_CATEGORIES, permissions)}
                    urlName={ENDPOINTS.ASSET_CATEGORIES}
                    isDisabledReload={isExecuting}
                    pageName={t('master.asset_categories')}
                    onHandleReload={() => refetch()}
                  />
                )
              }}
            />
            {showRequiredError && !get(values, 'asset_category_id') && (
              <Error>{t('asset_category_required_error')}</Error>
            )}
          </Item>
          <Item
            label={t('asset_type_property_tax')}
            style={{
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'pre-line',
            }}
          >
            <AutoCompleteSelect
              name="asset_type_for_depreciable_property_tax_code"
              disabled={resultSelectedAssetCategory.isLoading || isEditMode}
              items={masterData.asset_type_for_depreciable_property_tax}
              valueField="code"
              textField="name_jp"
            />
          </Item>
          <Item
            label={t('asset_type_corporate_tax')}
            style={{
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'pre-line',
            }}
          >
            <AutoCompleteSelect
              name="asset_type_for_corporate_tax_code"
              items={masterData.asset_type_for_corporate_tax}
              disabled={resultSelectedAssetCategory.isLoading || isEditMode}
              valueField="code"
              textField="name_jp"
            />
            <VerticalLine style={{ position: 'absolute', top: 38, left: -146 }} />
            <VerticalLine style={{ position: 'absolute', top: 33, left: 120 }} />
          </Item>
          <Item label={t('structure')}>
            <Input
              name="asset_structure_for_corporate_tax"
              placeholder={t('placeholder.under_50')}
              disabled={resultSelectedAssetCategory.isLoading || isEditMode}
              maxLength={50}
            />
            <VerticalLine style={{ position: 'absolute', top: 29, left: -146 }} />
            <VerticalLine style={{ position: 'absolute', top: 29, left: 120 }} />
          </Item>
          <Item label={t('detail')}>
            <Input
              name="asset_detail_for_corporate_tax"
              disabled={resultSelectedAssetCategory.isLoading || isEditMode}
              placeholder={t('placeholder.under_50')}
              maxLength={50}
            />
          </Item>
          {renderAcquisitionSection()}
          <Item label={t('area')} required>
            <FormikInfiniteScrollSelect
              name="area_biid"
              valueField="biid"
              textField="name"
              additionalQueries={validAtFilter}
              disabled={isEditMode}
              executeInfiniteScroll={({ textSearch, pageSize, page, additionalQueries: validAtFilter }) => {
                return searchAreas({
                  filters: { name: textSearch, valid_at: validAtFilter },
                  pageSize,
                  pageNumber: page,
                })
              }}
              onChange={handleAreaChanged}
              className={classNames({ error: showRequiredError && !get(values, 'area_biid') })}
              renderFooter={({ refetch, isLoading, isRefetching, isFetching }) => {
                const isExecuting = isRefetching || isLoading || isFetching
                return (
                  <RedirectToMasterPageLink
                    permissions={handlePermissionByFeatureId(FEATURES_ID.MASTER_AREAS, permissions)}
                    urlName={ENDPOINTS.AREAS}
                    isDisabledReload={isExecuting}
                    pageName={t('master.areas')}
                    onHandleReload={() => refetch()}
                  />
                )
              }}
            />
            {showRequiredError && !get(values, 'area_biid') && <Error>{t('area_required_error')}</Error>}
          </Item>
          <Item label={t('submit_destination')}>
            <UnderlineInput name="area.submit_destination.name" placeholder={t('submit_destination_placeholder')} />
          </Item>
          <Item label={t('department_allocation_rate')}>
            <FormikInfiniteScrollSelect
              name="department_biid"
              valueField="biid"
              textField="name"
              additionalQueries={validAtFilter}
              onChange={handleDepartmentChanged}
              executeInfiniteScroll={({ textSearch, pageSize, page, additionalQueries: validAtFilter }) => {
                return searchDepartments({
                  filters: { name: textSearch, valid_at: validAtFilter },
                  pageSize,
                  pageNumber: page,
                })
              }}
              renderFooter={({ refetch, isLoading, isRefetching, isFetching }) => {
                const isExecuting = isRefetching || isLoading || isFetching
                return (
                  <RedirectToMasterPageLink
                    permissions={handlePermissionByFeatureId(FEATURES_ID.MASTER_DEPARTMENT, permissions)}
                    urlName={ENDPOINTS.DEPARTMENTS}
                    isDisabledReload={isExecuting}
                    pageName={t('master.departments')}
                    onHandleReload={() => refetch()}
                  />
                )
              }}
            />
          </Item>
        </LeftGroup>
        <RightGroup>
          <Item label={t('quantity_unit')}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <NumberInput
                name="quantity"
                style={{ width: 130 }}
                type="real"
                onChange={() => handleQuantityChanged(false)}
                afterChanged={() => handleQuantityChanged(true)}
              />
              <AutoCompleteSelect
                name="unit_id"
                style={{ width: 85 }}
                items={units}
                valueField="id"
                textField="name"
                childrenNode={
                  <RedirectToMasterPageLink
                    permissions={handlePermissionByFeatureId(FEATURES_ID.MASTER_UNITS, permissions)}
                    urlName={ENDPOINTS.UNITS}
                    pageName={t('master.units')}
                    onHandleReload={() => onHandleReloadMasterOptions && onHandleReloadMasterOptions(t('master.units'))}
                  />
                }
              />
            </div>
          </Item>
          <Item label={t('acquire_destination')}>
            <Input name="acquire_destination_name" placeholder={t('placeholder.under_50')} maxLength={50} />
          </Item>
          <Item label={t('taxation_standard_special')}>
            <Checkbox
              name="has_exemption"
              afterChanged={handleAfterCheckExemption}
              style={{ color: '#333', marginTop: 5 }}
            >
              {t('has_exemption')}
            </Checkbox>
            {hasExamption && (
              <div style={{ display: 'flex', marginTop: 10 }}>
                <NumberInput
                  name="exemption_numerator"
                  style={{ width: 70, marginRight: 10 }}
                  placeholder={t('numerator')}
                  min={1}
                  max={99}
                  maxLength={2}
                />
                <label style={{ color: '#D4D8DD' }}>/</label>
                <NumberInput
                  name="exemption_denominator"
                  style={{ width: 70, marginLeft: 10 }}
                  placeholder={t('denomiator')}
                  min={1}
                  max={99}
                  maxLength={2}
                />
              </div>
            )}
          </Item>
          <Item
            label={t('description_property_tax_return')}
            style={{
              whiteSpace: 'pre-line',
            }}
          >
            <TextArea
              name="summary_for_asset_depreciation_tax"
              style={{ height: 50 }}
              placeholder={t('placeholder.under_100')}
              maxLength={100}
            />
          </Item>
        </RightGroup>
      </UnborderCollapse>
      <HorizontalLine />
      <FormGroup style={{ marginTop: 15 }}>
        <LeftGroup>
          <Item label={t('reference_link')}>
            <LinkListField name="urls" />
          </Item>
          <Item label={t('attachment')}>
            <UploadFileListField name="attachments" />
          </Item>
        </LeftGroup>
        <RightGroup>
          <Item label={t('photo')}>
            <UploadImageListField name="photos" />
          </Item>
        </RightGroup>
      </FormGroup>
      <HorizontalLine />
      <Heading>
        <HeadingTitle>{t('depreciation')}</HeadingTitle>
        <Checkbox name="has_transition" disabled={isEditMode} afterChanged={handleChangeTransitionCheckBox}>
          {t('has_transition')}
        </Checkbox>
      </Heading>
      {initialValues && renderLedgers(initialValues.fixed_asset_ledgers, isEditMode)}
      <HorizontalLine />
      <UnborderCollapse title={t('title_other')} collapsable={false}>
        <LeftGroup>
          <Item label={t('tag')}>
            <TagSelect name="tags" options={getTagOptions()} />
          </Item>
          <Item label={t('note')}>
            <TextArea name="memo" style={{ height: 75 }} placeholder={t('placeholder.under_200')} maxLength={200} />
          </Item>
        </LeftGroup>
        <RightGroup>
          <Item label={t('comment')}>
            <TextArea name="comment" style={{ height: 75 }} placeholder={t('placeholder.under_200')} maxLength={200} />
          </Item>
        </RightGroup>
      </UnborderCollapse>
      <SubmitArea
        formMode={formMode}
        confirming={confirming}
        disable={Boolean(acquisitionCostReference && reductionAmount > acquisitionCostReference)}
      />
    </>
  )
}

type Props = {
  initialValues: Asset
  masterData?: any
  formMode: FORM_MODES
  isImportMode: boolean
  isTemporaryData: boolean
  onSaveTemp?: (dataType: string, data: any, extraKey?: string) => void
  onHandleReloadMasterOptions?: (pageName: string) => void
  showRequiredError?: boolean
  onSubmit?: (values: any) => void
  confirming?: boolean
}

interface IAssetCategory extends IAssetCategoriesItem {
  asset_type: number
}

export default FormData
