import { ASSET_CHANGE_SITUATION_TYPES } from 'common/constants'
import { isEqual } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetDetailPage } from 'store/asset/selectors'

export const useCheckIsDividingable = (isRetiredOrSold: boolean) => {
  const { asset, eventHistory } = useSelector(selectAssetDetailPage, isEqual)
  return useMemo(() => {
    const eventList = eventHistory?.list
    if (asset.acquisition_cost_excluded_excise) {
      if (asset.acquisition_cost_excluded_excise <= 1) return false
    } else if (asset.acquisition_cost <= 1) return false
    if (eventList && eventList.length > 0) {
      const dividingEvent = eventList.find(
        event => event?.change_situation?.code === ASSET_CHANGE_SITUATION_TYPES.DIVIDING
      )
      const isDestinationFA = dividingEvent?.dividing_source_fixed_asset
      if (isRetiredOrSold || isDestinationFA) {
        return false
      }
    }
    return true
  }, [asset, asset?.quantity, eventHistory])
}
