import { RefactorWarningMessage } from 'common/components/refactor-warning-message'
import { getNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import DepartmentDetailTable from 'components/organisms/settings/DepartmentDetailTable'
import { MainTemplate } from 'components/templates'
import withRouterTrap from 'components/withRouterTrap'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { cleanupDepartment, clearDepartmentItemErrors } from 'store/settings/department/actions'

import { useFetchDepartmentDetail } from './hooks/use-fetch-department-detai'

const { t, useNsReady } = getNs(['components/organisms/settings/department-form'])

const DepartmentDetail = withRouterTrap(() => {
  useNsReady()

  const { departmentData, loading, masterData, listDepartmentOnCloud, id, currentMonthlyClosingDate, biid } =
    useFetchDepartmentDetail()
  const code = useLocation<any>()?.state?.department?.code
  const areaCode = departmentData?.[0]?.code || code || ''
  const breadcrumb = useMemo(() => {
    const sectionName = `${t('department_code')}: ${areaCode}`
    return <BreadcrumbNavigation enableBackButton sectionName={sectionName} />
  }, [areaCode])

  return (
    <>
      <RefactorWarningMessage />
      <MainTemplate breadcrumb={breadcrumb}>
        <DepartmentDetailTable
          values={departmentData}
          activeId={id && parseInt(id, 10)}
          departmentBiid={biid}
          loading={loading}
          prepressCategories={masterData.prepress_category}
          onClearErrors={clearDepartmentItemErrors}
          currentMonthlyClosingDate={currentMonthlyClosingDate}
          listDepartmentOnCloud={listDepartmentOnCloud}
        />
      </MainTemplate>
    </>
  )
})

export const DepartmentDetailContainer = () => {
  const dispatch = useDispatch()
  return <DepartmentDetail path="/master/departments" onLeave={() => dispatch(cleanupDepartment())} />
}
