export const BE_BASE_URL = `${process.env.REACT_APP_API_BASE_URL || ''}/api`
export const BE_WS_URL =
  process.env.REACT_APP_ENV !== 'local'
    ? `${process.env.REACT_APP_API_BASE_URL || ''}/api/v1/ws`.replace('http', 'ws')
    : `http://localhost:3000/api/v1/ws`.replace('http', 'ws')

export const MAX_IMPORT_FILE_SIZE = 2 // MB
export const MAX_IMAGE_UPLOAD_SIZE = 5 // MB
export const MAX_FILE_UPLOAD_SIZE = 10 // MB
export const ENVIRONMENT = {
  LOCAL: 'local',
  HEROKU: 'heroku',
  STAGING: 'staging',
  PRODUCTION: 'production',
}

export const DATE_FORMAT = 'YYYY/MM/DD'
export const DATE_FORMAT_RFC = 'YYYY-MM-DD'
export const YEAR_MONTH_FORMAT = 'YYYY/MM'
export const YEAR_FORMAT = 'YYYY'
export const MONTH_FORMAT = 'MM'
export const TIME_FORMAT = 'HH:mm:ss'
export const DAY_FORMAT = 'DD'
export const DATETIME_FORMAT = 'YYYY/MM/DD. HH:mm:ss'
export const DATETIME_NO_DOT_FORMAT = 'YYYY/MM/DD HH:mm:ss'
export const MONTH_DAY_FORMAT = 'MM/DD'
export const IMAGE_UPLOAD_PATH = `${BE_BASE_URL}/v1/photos/upload`
export const FILE_UPLOAD_PATH = `${BE_BASE_URL}/v1/attachments/upload`
export const FILE_DOWNLOAD_PATH = `${BE_BASE_URL}/v1/attachments/download`
export const MAXLENGTH_TEXT = 50
export const EMPTY_SYMBOL = 'ー'

export const URL_AA_SUPPORT = 'https://biz.moneyforward.com/support/fixed-assets/'
export const URL_AA_LANDING_PAGE = 'https://biz.moneyforward.com/fixed-assets'
export const URL_AA_VIEW_RELEASE = `${URL_AA_SUPPORT}news/`
export const URL_AA_NEW_ANNOUCEMENT = `${URL_AA_SUPPORT}news/important/20210929.html`
export const URL_REOPEN_MONTHLY_GUIDE = `${URL_AA_SUPPORT}guide/monthly/monthly_closing.html#release`
export const URL_ZENDECK = 'https://mfccs.zendesk.com/hc/ja/requests/new'
export const ZENDECK_SERVICE_NAME = '7'
export const ZENDECK_TICKET_FORM_ID = '900000305186'
export const URL_TERMS_OF_SERVICE = 'https://biz.moneyforward.com/agreement'
export const URL_PRIVACY_POLICY = 'https://corp.moneyforward.com/privacy'
export const URL_ANNOUNCEMENT_REGARDING_EXTERNAL_SENDING_TOOL = `${URL_PRIVACY_POLICY}/external-tools`
