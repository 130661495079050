import { loadNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import { ImportSettingForm } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import { isEqual } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLoadingApp } from 'store/app/selectors'

const t = loadNs(['pages/settings/import'])

export const ImportContainer = () => {
  const isLoadingApp = useSelector(selectLoadingApp, isEqual)

  const breadcrumb = <BreadcrumbNavigation sectionName={t('migration')} />

  return (
    <MainTemplate breadcrumb={breadcrumb} isLoadingApp={isLoadingApp}>
      <ImportSettingForm />
    </MainTemplate>
  )
}
