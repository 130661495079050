import { ModalRef } from 'aa_common/front-end'
import { ExciseSetting } from 'common/models'
import { ExciseFormProp } from 'components/organisms'
import { useCallback, useRef } from 'react'
import { createExciseSetting } from 'store/settings/exciseSetting/actions'

interface CallbackParams {
  title: string
  setting: ExciseSetting
  allowSelectTerm?: boolean
}

export const useExciseSettingModal = (dispatch: any) => {
  const modalRef = useRef<ModalRef<ExciseFormProp>>(null)
  const openModal = useCallback(
    (params: CallbackParams) => {
      const { setting: initialValues, allowSelectTerm = true } = params
      if (initialValues) {
        modalRef.current?.open(
          {
            ...params,
            initialValues,
            onValuesCb: values => dispatch(createExciseSetting(values)),
          },
          { allowSelectTerm }
        )
      }
    },
    [dispatch]
  )
  return { openModal, modalRef }
}
