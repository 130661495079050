import { isEqual } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { fetchMasterData } from 'store/masterData/actions'
import { selectMasterData } from 'store/masterData/selectors'
import {
  fetchByBiid,
  fetchDepartmentsOnCloudRequest,
  partialCleanupDepartment,
} from 'store/settings/department/actions'
import { selectDepartmentItem, selectDepartmentOnCloud } from 'store/settings/department/selectors'

export const useFetchDepartmentDetail = () => {
  const dispatch = useDispatch()
  const { departmentData, loading } = useSelector(selectDepartmentItem, isEqual)
  const masterData = useSelector(selectMasterData, isEqual)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const { list: listDepartmentOnCloud } = useSelector(selectDepartmentOnCloud, isEqual)

  const { biid, id } = useParams<any>()

  const currentMonthlyClosingDate = currentMonthlyClosing?.from_term_month?.end_date

  useEffect(() => {
    dispatch(fetchByBiid(biid))
    dispatch(fetchMonthlyClosingCurrent())
    dispatch(fetchMasterData())
    dispatch(fetchDepartmentsOnCloudRequest())

    return function cleanup() {
      dispatch(partialCleanupDepartment())
    }
  }, []) // eslint-disable-line

  return {
    departmentData,
    loading,
    masterData,
    currentMonthlyClosingDate,
    listDepartmentOnCloud,
    biid,
    id,
  }
}
