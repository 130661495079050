import * as assetApi from 'api/app/asset'
import { checkRetroActiveFixedAsset, getAssetPayload } from 'common/helpers'
import { LedgerSetting } from 'common/models'
import { handleErrorResponse } from 'common/utils/response-helper'
import { validateRequiredField } from 'components/organisms/assets/AssetForm/helper'
import { getGeneralValidationErrors } from 'components/organisms/assets/AssetForm/validations/asset-schema'
import useRetroactiveModal from 'components/templates/modal/RetroactiveAssetModal'
import useResource from 'lib/hooks/useResource'
import isEqual from 'lodash/isEqual'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { createAsset } from 'store/asset/actions'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'
import { selectTempDataData } from 'store/tempData/selectors'

import { createTempData } from '../../../../store/tempData/actions'

export const useAssetNewActions = () => {
  const dispatch = useDispatch()
  const confirmModal = useRetroactiveModal()

  const [validateAssetRes, validateAsset] = useResource(assetApi.create)
  const isValidateAssetLoading = validateAssetRes.isLoading

  const [showRequiredError, setShowRequiredError] = useState<boolean>(false)
  const [asset, setAsset] = useState()

  const ledgerSettings: LedgerSetting[] = useSelector(selectLedgerSettingList, isEqual)
  const tempAssetData = useSelector(selectTempDataData, isEqual)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const [validateErrors, setValidateErrors] = useState(null)

  const handleSubmit = (assetItemParams: any) => {
    const assetItem = getAssetPayload(assetItemParams)
    setAsset(assetItem)
    if (validateRequiredField(assetItem) || !!getGeneralValidationErrors().join(',')) {
      setShowRequiredError(true)
      return
    }

    validateAsset({ ...assetItem, dry_run: true })
      .then(res => {
        if (res.error) {
          handleErrorResponse(
            {
              response: {
                status: res.status,
                data: { errors: res.error },
              },
            },
            setValidateErrors
          )
        }

        return checkRetroActiveFixedAsset({
          res,
          assetItem,
          currentMonthlyClosing,
          confirmModal,
        })
      })
      .then(isOK => {
        isOK === true && dispatch(createAsset(assetItem))
      })
  }

  const handleSaveTemp = (dataType: string, data: any) => {
    dispatch(createTempData(dataType, data))
  }
  return {
    validateErrors,
    isValidateAssetLoading,
    showRequiredError,
    asset,
    handleSubmit,
    ledgerSettings,
    tempAssetData,
    confirmModal,
    dispatch,
    handleSaveTemp,
  }
}
