import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { defaultTo, ifElse } from 'aa_common/front-end/helpers'
import { Menu } from 'antd'
import { DEPRECIATION_METHOD_CODE, LEDGER_PURPOSE } from 'common/constants'
import { getNs } from 'common/i18n-config'
import { AssetLedger } from 'common/models'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetDetailPage } from 'store/asset/selectors'

import { StyledDropdown, StyledDropdownContent, StyledMenu } from '../styles'

const { t, useNsReady } = getNs('components/organisms/assets/asset-detail')

export const DepreciationAdjustmentDropdown = () => {
  useNsReady()
  const { asset } = useSelector(selectAssetDetailPage, isEqual)
  const [isShowDepreciationAdjustment, setShowDepreciationAdjustment] = useState(false)

  const fixedAssetLedgers = defaultTo(get(asset, 'fixed_asset_ledgers'), [])
  const accountantLedger = fixedAssetLedgers.find(
    (assetLedger: AssetLedger) => assetLedger.ledger_setting?.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING
  )

  const isDisabled = useMemo(() => {
    const isMethodExcluded = [
      DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET,
      DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE,
      DEPRECIATION_METHOD_CODE.NON_DEPRECIATION,
    ].includes(accountantLedger?.depreciation_method_code as number)

    const isSoldOrRetiredOrImpaired = !!asset?.sold_at || !!asset?.retired_at || !!asset?.impaired_at

    const COUNTINUE_DEPRECIATION_CODE = 1
    const isContinueToDepreciate =
      accountantLedger?.depreciation_method_code === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD &&
      accountantLedger?.ledger_setting?.bulk_equal_installment_depreciation_method === COUNTINUE_DEPRECIATION_CODE

    return isMethodExcluded || isSoldOrRetiredOrImpaired || isContinueToDepreciate
  }, [asset, accountantLedger])

  return (
    <StyledDropdown
      overlay={<DropdownMenu isRegistered={false} />}
      onVisibleChange={setShowDepreciationAdjustment}
      trigger={['click']}
      visible={isShowDepreciationAdjustment}
      disabled={isDisabled}
      className={ifElse(isDisabled, 'disabled', '')}
    >
      <StyledDropdownContent>
        <span>{t('depreciation_adjustment')}</span>
        <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: 5, paddingBottom: 3 }} />
      </StyledDropdownContent>
    </StyledDropdown>
  )
}

const DropdownMenu = ({ isRegistered }: { isRegistered: boolean }) => (
  <StyledMenu>
    <Menu.Item>{t('accelerated_depreciation_registration')}</Menu.Item>
    <Menu.Item disabled={!isRegistered}>{t('cancel_accelerated_depreciation')}</Menu.Item>
  </StyledMenu>
)
