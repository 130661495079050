import { isEqual } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectMasterData } from 'store/masterData/selectors'
import { createDepartment, fetchDepartment, partialCleanupDepartment } from 'store/settings/department/actions'
import { selectDepartmentItem } from 'store/settings/department/selectors'

export const useDepartmentCopy = () => {
  const { id } = useParams<any>()

  const masterData = useSelector(selectMasterData, isEqual)
  const { departmentData: department, errors, loading } = useSelector(selectDepartmentItem, isEqual)
  const dispatch = useDispatch()

  const handleSubmit = (values: any) => {
    dispatch(createDepartment(values))
  }

  useEffect(() => {
    dispatch(fetchDepartment(id))
    return () => {
      dispatch(partialCleanupDepartment())
    }
  }, [dispatch, id])
  return {
    department,
    errors,
    loading,
    masterData,
    handleSubmit,
  }
}
