import { ifElse, someTrue } from 'aa_common/front-end'
import { ErrorsList, ExportModal, ImportModal } from 'aa_common/front-end/components'
import { CsvCharset } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import { ListPageTemplate } from 'components/templates'
import { useParserHtmlFromI8n } from 'hooks/useParserHtmlFromI8n'
import i18n from 'i18n'
import { get } from 'immutable'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectCauseTypes } from 'store/masterData/selectors'
import { cleanupCause, deleteCause, exportCauses, fetchCauseListAuth, importCauses } from 'store/settings/cause/actions'
import { selectCauseList } from 'store/settings/cause/selectors'

import { CauseList } from './components'

export const CauseListContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { data, importError, listLoading } = useSelector(selectCauseList, isEqual)
  const causeTypes = useSelector(selectCauseTypes, isEqual)
  const [isShowExportModal, setIsShowExportModal] = useState(false)

  const breadcrumb = <BreadcrumbNavigation sectionName={i18n.t('pages.settings.Causes.list')} />

  useEffect(() => {
    dispatch(fetchCauseListAuth())
    return function cleanup() {
      dispatch(cleanupCause())
    }
  }, [fetchCauseListAuth, cleanupCause, dispatch])

  const handleAddNew = () => {
    history.push('/master/causes/new')
  }

  const handleExport = (charset: CsvCharset) => {
    setIsShowExportModal(false)
    dispatch(exportCauses(charset))
  }

  const importModalRef = useRef<any>(null)
  const [preImportErrs, setPreImportErrs] = useState<null>()
  const handleImportClick = () => {
    importModalRef.current?.open({
      onValuesCb(params: any) {
        const { charset, file } = params || {}
        setPreImportErrs(ifElse(file, null, params))
        if (!file) return

        dispatch(importCauses(charset, file))
      },
    })
  }

  const handleCopy = (item: any) => {
    history.push(`/master/causes/${item.id}/copy`)
  }

  const handleEdit = (item: any) => {
    history.push(`/master/causes/${item.id}/edit`)
  }

  const handleDelete = (item: any) => {
    dispatch(deleteCause(item))
  }

  const handleExportClick = () => setIsShowExportModal(true)

  const errorsList = someTrue(importError.message, get(importError, 'details', []).length > 0) && (
    <ErrorsList
      title={someTrue(importError.message, i18n.t('common.messages.can_not_import'))}
      errors={importError.details}
    />
  )
  const exportModalContent = useParserHtmlFromI8n('common.form_values.export_modal_content', {
    name: i18n.t('components.CauseList.cause'),
    returnObjects: true,
  }).content

  const importModalContent = useParserHtmlFromI8n('common.form_values.import_modal_content', {
    name: i18n.t('components.CauseList.cause'),
    fileUrl: `${process.env.PUBLIC_URL}/files/import_samples/cause_sample.csv`,
    fileName: 'cause_sample.csv',
    returnObjects: true,
  }).content

  return (
    <ListPageTemplate
      maxWidth={700}
      breadcrumb={breadcrumb}
      errors={(preImportErrs && <ErrorsList errors={preImportErrs} />) || errorsList}
    >
      <CauseList
        causes={data}
        masterData={causeTypes}
        onCopy={handleCopy}
        onEdit={handleEdit}
        onDelete={handleDelete}
        handleAddNew={handleAddNew}
        handleExportClick={handleExportClick}
        handleImportClick={handleImportClick}
        loading={listLoading}
      />
      <ExportModal
        title={i18n.t('common.form_values.export_modal_title', { name: i18n.t('components.CauseList.cause') })}
        isShow={isShowExportModal}
        onCancel={() => setIsShowExportModal(false)}
        onExport={handleExport}
      >
        {exportModalContent}
      </ExportModal>
      <ImportModal
        ref={importModalRef}
        title={i18n.t('common.form_values.import_modal_title', { name: i18n.t('components.CauseList.cause') })}
      >
        {importModalContent}
      </ImportModal>
    </ListPageTemplate>
  )
}
