import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { DepartmentDetailContainer } from 'containers/settings/department-detail-container'
import React from 'react'

import { DepartmentDetailPageWrapperOld } from './department-detail-page'

const DepartmentDetailPage = () => {
  const { isEnable: isEnableRefactorPagePhase6 } = useCheckFlag(FeatureFlagNames.RefactorPagePhase6)

  return <>{isEnableRefactorPagePhase6 ? <DepartmentDetailContainer /> : <DepartmentDetailPageWrapperOld />}</>
}

export default DepartmentDetailPage
