import { ifElse } from 'aa_common/front-end'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { RoleDetailContainer } from 'containers/settings/role-detail-container/role-detail-container'
import React from 'react'

import { RoleDetailPageOld } from './role-detail-page'

const RoleNewPage = () => {
  const { isEnable: isRefactorPagePhase7 } = useCheckFlag(FeatureFlagNames.RefactorPagePhase7)
  return <>{ifElse(isRefactorPagePhase7, <RoleDetailContainer />, <RoleDetailPageOld />)}</>
}

export default RoleNewPage
