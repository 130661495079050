import { API_STATUS_CODE } from 'common/constants'
import Rollbar from 'rollbar'

const IGNORE_TARGET = 'Uncaught Invariant Violation: Expected to find a valid target'
const IGNORED_ERRORS = [API_STATUS_CODE.UNAUTHORIZED.toString(), IGNORE_TARGET]

const rollbar = new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.NODE_ENV === 'production',
  scrubTelemetryInputs: true,
  checkIgnore: (_isUncaught, args, _payload) => {
    const errorMessage = args.join(',')
    return IGNORED_ERRORS.some((error: string) => errorMessage.includes(error))
  },

  payload: {
    environment: process.env.REACT_APP_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.REACT_APP_GIT_SHA,
        guess_uncaught_frames: true,
      },
    },
  },
})

export default rollbar
