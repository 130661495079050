import { AssetCodeSettings, ExciseSettings, IMasterData } from 'common/models'

export const selectMasterData = (state: any): IMasterData => {
  return state.getIn(['masterData', 'data'])
}

export const selectMasterTaxDeclarationYear = (state: any) => {
  const masterTaxDeclaration: any[] = state.getIn(['masterData', 'data', 'depreciable_assets_tax_declaration']) || []
  const currentTaxInfo = masterTaxDeclaration.find((item: any) => item.key === 'current')

  return {
    masterTaxDeclaration,
    currentTaxInfo,
  }
}

export const selectAssetCodeSettings = (state: any): AssetCodeSettings => {
  return state.getIn(['masterData', 'data', 'asset_code_settings'])
}

export const selectExciseSettings = (state: any): ExciseSettings => {
  return state.getIn(['masterData', 'data', 'excise_settings'])
}

export const selectCauseTypes = (state: any) => {
  return state.getIn(['masterData', 'data', 'cause_type'])
}
