import { ifElse } from 'aa_common/front-end'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { CauseNewContainer } from 'containers/master/causes'
import React from 'react'

import { CauseNewPageOld } from './cause-list-page'

const CauseNewPage = () => {
  const { isEnable: isRefactorCauseNewPage } = useCheckFlag(FeatureFlagNames.RefactorPagePhase6)

  return <>{ifElse(isRefactorCauseNewPage, <CauseNewContainer />, <CauseNewPageOld />)}</>
}

export default CauseNewPage
