/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { handleAuthError, handleErrors, isAuthError } from 'api/app/base'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { getErrorMsg } from 'common/helpers/response-helper'
import qs from 'qs'

import { CSRF_TOKEN_KEY } from '../constants'
import { parseErrorResponse, showToastErrors } from '../utils/response-helper'
import { isAxiosSilent } from './helper'

const baseURL = `${process.env.REACT_APP_API_BASE_URL || ''}/api/v1`

const defaultConfig: AxiosRequestConfig<any> = {
  timeout: 60000,
  withCredentials: true,
  baseURL,
}

const addRequestInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (config: AxiosRequestConfig<any>) => {
      config.headers = config.headers || {}
      config.headers['X-CSRF-Token'] = localStorage.getItem(CSRF_TOKEN_KEY) || ''
      ;(config as any).paramsSerializer = (params: any) => {
        return qs.stringify(params, {
          arrayFormat: 'comma',
          encode: false,
        })
      }
      return config
    },
    (error: any) => {
      console.error(getErrorMsg(error))
      return Promise.reject(error)
    }
  )
}

const addResponseInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response: any) => response?.data,
    async error => {
      const { response } = error

      // Check Auth Error
      if (isAuthError(response)) {
        return handleAuthError(response, instance)
      }

      // handle other errors
      handleErrors(response)
      if (!isAxiosSilent(error.config)) {
        const errors = parseErrorResponse(error)
        showToastErrors(errors)
      }
      return Promise.reject(error)
    }
  )
}

export const createAxiosClient = (config: AxiosRequestConfig = defaultConfig) => {
  const instance = axios.create(config)
  addRequestInterceptors(instance)
  addResponseInterceptors(instance)
  return instance
}

/**
 * api with interceptors
 */
export const axiosClient = createAxiosClient()
