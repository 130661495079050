import { getNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import CauseForm from 'components/organisms/settings/CauseForm'
import { MainTemplate } from 'components/templates'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cleanupCause, createCause } from 'store/settings/cause/actions'
import { selectCauseItem } from 'store/settings/cause/selectors'

const { t, useNsReady } = getNs(['pages/settings/causes'])

export const CauseNewPageOld = () => {
  useNsReady()
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('add')} />
  const dispatch = useDispatch()
  const causeItemSelector = useSelector(selectCauseItem, isEqual)

  useEffect(() => {
    return function cleanup() {
      dispatch(cleanupCause())
    }
  }, []) // eslint-disable-line

  const handleSubmit = useCallback(
    (values: any) => {
      dispatch(createCause(values))
    },
    [dispatch]
  )

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <CauseForm errors={causeItemSelector.errors} onSubmit={handleSubmit} loading={causeItemSelector.loading} />
    </MainTemplate>
  )
}
