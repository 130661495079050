import styled from '@emotion/styled/macro'
import { Dropdown, Menu } from 'antd'
import { BLUE_COLOR, GREY_BORDER_COLOR } from 'common/constants/styles'

export const Wrapper = styled.div`
  .depreciation-cell {
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }
  }

  td.depreciation-cell {
    &.month-simulation {
      padding: 0;
    }
  }

  col.ant-table-expand-icon-col {
    width: 30px;
  }

  .ant-table-expanded-row.month-simulation-detail {
    > .ant-table-cell {
      padding: 0;
    }

    .ant-table {
      border: unset;
      margin: 0;
    }
  }
`

export const Section = styled.section``

export const SectionHeaderText = styled.h4`
  font-weight: 800;
  font-size: 13px;
  line-height: 150%;
`

export const WrapperLedger = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  .ant-btn {
    border: none;
    border-radius: 15px;
    margin-right: 10px;
  }

  .disable {
    color: #333333;
    background-color: #d4d8dd;
  }

  .ant-btn:hover,
  .active {
    background-color: #3b7de9;
    color: #ffffff;
  }
`
export const StyledDropdownContent = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  width: fit-content;
  border: 1px solid ${GREY_BORDER_COLOR};
  border-radius: 4px;
  height: 30px;

  & > span {
    color: ${BLUE_COLOR};
  }

  &:hover {
    cursor: pointer;
  }
`

export const StyledMenu = styled(Menu)`
  min-width: 190px;
  border: 1px solid ${GREY_BORDER_COLOR};
  border-radius: 4px;
  padding: 4px 0;

  .ant-menu-item {
    margin: 0;
    color: #303030;
    background-color: unset;

    &:hover {
      background-color: #ebf1fb;
      color: #303030;
    }
  }
`

export const StyledSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledDropdown = styled(Dropdown)`
  &.disabled {
    color: #919191;
    background-color: #e2e2e2;
    cursor: not-allowed;

    & > span {
      color: #919191;
    }
  }
`
