import { connect, disconnect, send } from '@giantmachines/redux-websocket'
import { removeCookieByName, setCookieWithTime } from 'aa_common/front-end/helpers'
import { Office } from 'aa_common/front-end/models'
import * as officeApi from 'api/app/office'
import * as sessionApi from 'api/app/session'
import * as termOfUseAgreementApi from 'api/app/termOfUseAgreement'
import { AxiosResponse } from 'axios'
import {
  BE_WS_URL,
  CSRF_TOKEN_KEY,
  FE_SIGNIN_PATH,
  LOGIN_FIRST_TIME_KEY,
  MFID_NONCE_KEY,
  MFID_REDIRECT_URI_KEY,
  MFID_STATE_KEY,
  SESSION_INFO,
  SSO_REDIRECT_PATH_KEY,
  SSO_TENANT_UID_KEY,
  URL_AA_LANDING_PAGE,
} from 'common/constants'
import { parseError } from 'common/helpers'
import { buildNavisNewTenantURL } from 'common/helpers/navis-helper'
import i18n from 'i18n'
import { get } from 'lodash'
import { call, delay, put, select, take, takeLatest } from 'redux-saga/effects'
import {
  CHANGE_LOCATION,
  changeLocation,
  REDIRECT_URL,
  redirectUrl,
  SET_APP_MESSAGE,
  showErrorsAppCall,
} from 'store/app/actions'
import { selectOfficeRequest } from 'store/office/actions'
import {
  CREATE_SESSION_FAILURE,
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_SSO_REQUEST,
  CREATE_SESSION_SUCCESS,
  createSessionRequest,
  createSessionSSORequest,
  FETCH_CURRENT_OFFICE_FAILURE,
  FETCH_CURRENT_OFFICE_REQUEST,
  FETCH_CURRENT_OFFICE_SUCCESS,
  FETCH_CURRENT_USER_FAILURE,
  FETCH_CURRENT_USER_REQUEST,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_USER_INFO_FAILURE,
  FETCH_USER_INFO_REQUEST,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_PERMISSION_REQUEST,
  fetchNotificationsFailure,
  fetchNotificationsRequest,
  fetchNotificationsSuccess,
  fetchUserPermissionRequest,
  HANDLE_CHECK_MFID_CALLBACK,
  HANDLE_CHECK_OFFICES,
  HANDLE_LOGOUT,
  handleFetchUserPermission,
  ON_WEBSOCKET_CLOSED,
  ON_WEBSOCKET_MESSAGE,
  ON_WEBSOCKET_OPEN,
  OPEN_WEBSOCKET_REQUEST,
  returnNoOfficesMessage,
  SAVE_RECENT_OFFICE,
  STATUS_CONNECT,
  STATUS_DISCONNECT,
  STATUS_OPEN,
  SUBMIT_TERM_OF_USE_REQUEST,
  VERIFY_AGENT_LOGIN_REQUEST,
} from 'store/session/actions'

import {
  CreateSessionRequest,
  CreateSessionSSORequest,
  FetchCurrentOfficeFailure,
  FetchCurrentOfficeSuccess,
  FetchCurrentUserFailure,
  FetchCurrentUserSuccess,
  FetchUserInfoSuccess,
  VerifyAgentLoginRequest,
} from './action.model'
import { selectWsConnectionStatus } from './selectors'

export function* createSession({ payload }: CreateSessionRequest) {
  try {
    const res: AxiosResponse = yield call(sessionApi.create, payload)
    yield localStorage.setItem(CSRF_TOKEN_KEY, res.data.data.csrf_token)
    yield put({ type: CREATE_SESSION_SUCCESS })

    const currentUserResponse: AxiosResponse = yield call(sessionApi.getCurrentUser)
    const isConfirm = get(currentUserResponse, 'data.data.is_confirmed')
    if (isConfirm) {
      yield put({ type: HANDLE_CHECK_OFFICES })
    } else {
      yield put({ type: CHANGE_LOCATION, payload: '/term-of-use' })
    }
  } catch (err) {
    const errors = get(err, 'response.data.errors', [])
    yield put({ type: CREATE_SESSION_FAILURE, payload: errors })
    yield put({ type: CHANGE_LOCATION, payload: '/' })
    yield put({
      type: SET_APP_MESSAGE,
      payload: {
        type: 'failure',
        content: i18n.t('common.messages.logged_failure'),
      },
    })
  }
}

export function* createSessionSSO({ payload }: CreateSessionSSORequest) {
  try {
    const sessionResponse: AxiosResponse = yield call(sessionApi.create, payload)
    yield localStorage.setItem(CSRF_TOKEN_KEY, get(sessionResponse, 'data.data.csrf_token'))

    // Check office has been existed or not
    const officesResponse: AxiosResponse = yield call(officeApi.creatableList)
    const offices: Office[] = get(officesResponse, 'data.data') || []
    const isTenantUidExisted = offices.find(office => office.id === payload.tenant_uid)

    if (isTenantUidExisted) {
      // Check user has been belong to the tanant_uid or not
      const userOfficesResponse: AxiosResponse = yield call(officeApi.list)
      const userOffices: Office[] = get(userOfficesResponse, 'data.data') || []
      const isUserBelongToTenantUid = userOffices.find(office => office.id === payload.tenant_uid)

      if (isUserBelongToTenantUid) {
        const officeSelectResponse: AxiosResponse = yield call(officeApi.select, { office_id: payload.tenant_uid })
        yield localStorage.setItem(CSRF_TOKEN_KEY, get(officeSelectResponse, 'data.data.csrf_token'))
        yield put({ type: SAVE_RECENT_OFFICE, payload: payload.tenant_uid })

        if (payload.redirect_path) {
          yield put({ type: CHANGE_LOCATION, payload: payload.redirect_path })
        } else {
          yield put({ type: CHANGE_LOCATION, payload: '/' })
        }
      } else {
        yield put({ type: REDIRECT_URL, payload: URL_AA_LANDING_PAGE })
      }
    } else if (payload.redirect_path === '/offices/select') {
      yield put({ type: CHANGE_LOCATION, payload: payload.redirect_path })
    } else {
      yield put({ type: REDIRECT_URL, payload: URL_AA_LANDING_PAGE })
    }
  } catch (err) {
    yield put({ type: REDIRECT_URL, payload: URL_AA_LANDING_PAGE })
  }
}

function* getUserInfo() {
  try {
    const res: AxiosResponse = yield call(sessionApi.getUserInfo)
    sessionStorage.setItem(SESSION_INFO, JSON.stringify(res.data.data ?? {}))
    yield put<FetchUserInfoSuccess>({ type: FETCH_USER_INFO_SUCCESS, userInfo: res.data.data })
    yield put(fetchUserPermissionRequest())
    yield put(fetchNotificationsRequest())
  } catch (err) {
    const errors = get(err, 'response.data.errors', [])
    yield put({ type: FETCH_USER_INFO_FAILURE, payload: errors })
    yield put(showErrorsAppCall(parseError(err)))
  }
}

export function* getCurrentUser() {
  try {
    const res: AxiosResponse = yield call(sessionApi.getCurrentUser)
    yield put<FetchCurrentUserSuccess>({ type: FETCH_CURRENT_USER_SUCCESS, payload: res.data.data })
  } catch (err) {
    const errors = get(err, 'response.data.errors', [])
    yield put<FetchCurrentUserFailure>({ type: FETCH_CURRENT_USER_FAILURE, payload: errors })
    yield put(showErrorsAppCall(parseError(err)))
  }
}

export function* fetchCurrentOffice() {
  try {
    const res: AxiosResponse = yield call(sessionApi.getCurrentOffice)
    yield put<FetchCurrentOfficeSuccess>({ type: FETCH_CURRENT_OFFICE_SUCCESS, payload: res.data.data })
    yield delay(5000)
  } catch (err) {
    yield put<FetchCurrentOfficeFailure>({ type: FETCH_CURRENT_OFFICE_FAILURE })
    yield put(showErrorsAppCall(parseError(err)))
  }
}

function* fetchUserPermission() {
  try {
    const res: AxiosResponse = yield call(sessionApi.getUserPermission)
    yield put(handleFetchUserPermission(res?.data?.data?.permissions || null))
  } catch (err) {
    yield put(handleFetchUserPermission(null))
    yield put(showErrorsAppCall(parseError(err)))
  }
}

function* fetchNotifications() {
  try {
    const res: AxiosResponse = yield call(sessionApi.fetchNotifications)
    yield put(fetchNotificationsSuccess(res?.data?.data))
  } catch (err: any) {
    const errors = err?.response?.data?.errors || []
    yield put(fetchNotificationsFailure(errors))
    yield put(showErrorsAppCall(parseError(err)))
  }
}

function* submitTermOfUse() {
  try {
    const submitTermOfUseResponse: AxiosResponse = yield call(termOfUseAgreementApi.create)
    yield localStorage.setItem(CSRF_TOKEN_KEY, get(submitTermOfUseResponse, 'data.data.csrf_token'))
    yield put({ type: HANDLE_CHECK_OFFICES })
  } catch (err) {
    yield put(showErrorsAppCall(parseError(err)))
  }
}

function* handleCheckMfidCallback({ payload }: any) {
  try {
    const { state, code } = payload

    const mfidState = sessionStorage.getItem(MFID_STATE_KEY)
    sessionStorage.removeItem(MFID_STATE_KEY)

    const mfidNonce = sessionStorage.getItem(MFID_NONCE_KEY)
    sessionStorage.removeItem(MFID_NONCE_KEY)

    const redirectUri = sessionStorage.getItem(MFID_REDIRECT_URI_KEY)
    sessionStorage.removeItem(MFID_REDIRECT_URI_KEY)

    const tenantUid = sessionStorage.getItem(SSO_TENANT_UID_KEY)
    sessionStorage.removeItem(SSO_TENANT_UID_KEY)

    const redirectPath = sessionStorage.getItem(SSO_REDIRECT_PATH_KEY)
    sessionStorage.removeItem(SSO_REDIRECT_PATH_KEY)

    if (state !== mfidState || code === null) {
      yield put({ type: CHANGE_LOCATION, payload: '/errors/404' })
    } else if (tenantUid) {
      yield put(createSessionSSORequest(code, mfidNonce, redirectUri, parseInt(tenantUid, 10), redirectPath))
    } else {
      yield put(createSessionRequest(code, mfidNonce, redirectUri))
    }
  } catch (err) {
    yield put(showErrorsAppCall(parseError(err)))
  }
}

function* handleCheckOffice() {
  try {
    const firstTime = sessionStorage.getItem(LOGIN_FIRST_TIME_KEY)
    if (firstTime === 'true') {
      const tenantOfficesResponse: AxiosResponse = yield call(officeApi.creatableList)
      const tenantoffices: Office[] = get(tenantOfficesResponse, 'data.data') || []

      if (tenantoffices.length === 0) {
        const currentUserResponse: AxiosResponse = yield call(sessionApi.getCurrentUser)
        const currentUser = get(currentUserResponse, 'data.data')

        yield put(redirectUrl(buildNavisNewTenantURL(currentUser ? currentUser.email : '')))
      } else {
        yield put(changeLocation('/offices/new'))
      }
    } else {
      const officesResponse: AxiosResponse = yield call(officeApi.list)
      const offices: Office[] = get(officesResponse, 'data.data') || []
      localStorage.setItem('invalidOffice', 'false')
      if (offices.length === 0) {
        yield put(returnNoOfficesMessage(true))
        localStorage.setItem('invalidOffice', 'true')
        yield put(changeLocation(FE_SIGNIN_PATH))
      } else if (offices.length === 1) {
        yield put(selectOfficeRequest(offices[0].id))
      } else {
        const currentUserResponse: AxiosResponse = yield call(sessionApi.getCurrentUser)
        const currentUser = get(currentUserResponse, 'data.data')
        const recentOfficeId = currentUser.last_office_id

        if (recentOfficeId && offices.findIndex(office => office.id === parseInt(recentOfficeId, 10)) !== -1)
          yield put(selectOfficeRequest(parseInt(recentOfficeId, 10)))
        else yield put(changeLocation('/offices/select'))
      }
    }
  } catch (err) {
    yield put(showErrorsAppCall(parseError(err)))
  }
}

function* handleLogout() {
  try {
    yield call(sessionApi.logout)
  } finally {
    yield put({
      type: SET_APP_MESSAGE,
      payload: { type: 'success', content: i18n.t('common.messages.logout_success') },
    })
    localStorage.removeItem(CSRF_TOKEN_KEY)
    removeCookieByName('is_agent_login')
    yield put(changeLocation('/login'))
  }
}

function* openWebSocketConn() {
  try {
    const status: ReturnType<typeof selectWsConnectionStatus> = yield select(selectWsConnectionStatus)
    if (status === STATUS_CONNECT) {
      yield take(ON_WEBSOCKET_OPEN)
      yield put(disconnect())
      yield take(ON_WEBSOCKET_CLOSED)
    } else if (status === STATUS_OPEN) {
      yield put(disconnect())
      yield take(ON_WEBSOCKET_CLOSED)
    } else if (status === STATUS_DISCONNECT) {
      yield take(ON_WEBSOCKET_CLOSED)
    }
  } finally {
    yield put(connect(BE_WS_URL))
  }
}
function* closeWebSocketConn() {
  const status: ReturnType<typeof selectWsConnectionStatus> = yield select(selectWsConnectionStatus)
  if (status === STATUS_CONNECT) {
    yield take(ON_WEBSOCKET_OPEN)
    yield put(disconnect())
  } else if (status === STATUS_OPEN) {
    yield put(disconnect())
  }
}
function* subscribePermissionUpdate() {
  yield put(send({ type: 'subscribe', topic: 'update_role' }))

  while (true) {
    yield delay(30 * 1000) // delay 30 sec
    const status: ReturnType<typeof selectWsConnectionStatus> = yield select(selectWsConnectionStatus)
    if (status === STATUS_OPEN) {
      yield put(send({ type: 'ping', topic: '' })) // ping to keep connect alive
    } else {
      yield put({ type: OPEN_WEBSOCKET_REQUEST }) // reconnect
      break
    }
  }
}
function* handleOnWsMessage(action: any) {
  const isPermissionUpdated = action?.payload?.message?.is_updated_role
  if (isPermissionUpdated) {
    yield put(fetchUserPermissionRequest())
  }
}

function* verifyAgentLogin({ payload }: VerifyAgentLoginRequest) {
  try {
    const res: AxiosResponse = yield call(sessionApi.verifyAgentLogin, payload)
    yield localStorage.setItem(CSRF_TOKEN_KEY, res?.data?.data?.csrf_token)
    yield setCookieWithTime('is_agent_login', 'true', 4)
    yield put(changeLocation('/'))
  } catch (err) {
    yield put(showErrorsAppCall(parseError(err)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(CREATE_SESSION_REQUEST, createSession)
  yield takeLatest(CREATE_SESSION_SSO_REQUEST, createSessionSSO)
  yield takeLatest(FETCH_USER_INFO_REQUEST, getUserInfo)
  yield takeLatest(FETCH_CURRENT_USER_REQUEST, getCurrentUser)
  yield takeLatest(FETCH_USER_PERMISSION_REQUEST, fetchUserPermission)
  yield takeLatest(FETCH_CURRENT_OFFICE_REQUEST, fetchCurrentOffice)
  yield takeLatest(FETCH_NOTIFICATIONS_REQUEST, fetchNotifications)
  yield takeLatest(SUBMIT_TERM_OF_USE_REQUEST, submitTermOfUse)
  yield takeLatest(HANDLE_CHECK_MFID_CALLBACK, handleCheckMfidCallback)
  yield takeLatest(HANDLE_CHECK_OFFICES, handleCheckOffice)
  yield takeLatest(HANDLE_LOGOUT, handleLogout)

  yield takeLatest([FETCH_USER_INFO_SUCCESS, OPEN_WEBSOCKET_REQUEST], openWebSocketConn)
  yield takeLatest(HANDLE_LOGOUT, closeWebSocketConn)
  yield takeLatest(ON_WEBSOCKET_OPEN, subscribePermissionUpdate)
  yield takeLatest(ON_WEBSOCKET_MESSAGE, handleOnWsMessage)
  yield takeLatest(VERIFY_AGENT_LOGIN_REQUEST, verifyAgentLogin)
}
