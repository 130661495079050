import { ifElse } from 'aa_common/front-end'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { CauseListContainer } from 'containers/master/causes/cause-list-container'
import React from 'react'

import CauseListPageOld from './cause_list_page'

const CauseListPage = () => {
  const { isEnable: isRefactorCauseListPage } = useCheckFlag(FeatureFlagNames.RefactorPagePhase5)
  return <>{ifElse(isRefactorCauseListPage, <CauseListContainer />, <CauseListPageOld />)}</>
}
export default CauseListPage
