import { Spin } from 'aa_common/front-end/antd'
import { RefactorWarningMessage } from 'common/components/refactor-warning-message'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import { RoleDetailForm } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import React from 'react'

import { useFetchRoleEdit } from './hooks/use-fetch-role-edit'

const t = loadNs(['pages/settings/roles'])

export const RoleEditContainer = () => {
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('role_detail')} />
  const { biid, loading, data } = useFetchRoleEdit()

  return (
    <>
      <RefactorWarningMessage />
      <MainTemplate breadcrumb={breadcrumb}>
        <Spin loading={loading}>
          <RoleDetailForm type={FORM_MODES.EDIT} biid={biid} data={data} />
        </Spin>
      </MainTemplate>
    </>
  )
}
