import React from 'react'

import { Wrapper } from './styles'

export const RefactorWarningMessage = () => {
  return (
    <Wrapper>
      <div>This is the refactor page</div>
    </Wrapper>
  )
}
