import { TempDataTypes } from 'api/app/tempData'
import { initialAssetLedgersFromSettings, mergeAssetLedgersWithLedgerSettings } from 'common/helpers'
import { Asset, LedgerSetting } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import { defaultAssetValues } from 'components/organisms/assets/AssetForm/helper'
import i18n from 'i18n'
import isEqual from 'lodash/isEqual'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cleanupAssetItem } from 'store/asset/actions'
import { selectAssetItemErrors, selectAssetItemLoading } from 'store/asset/selectors'
import { fetchTerms } from 'store/settings/accountingPeriod/actions'
import { selectTermsData } from 'store/settings/accountingPeriod/selectors'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'
import { clearTempData, fetchTempData } from 'store/tempData/actions'
import { selectTempDataData } from 'store/tempData/selectors'

export const useFetchDataAndConfirm = () => {
  const dispatch = useDispatch()
  const [isAppliedTemporary, setIsAppliedTemporary] = useState<boolean>(false)

  const [confirmVisible, setConfirmVisible] = useState<boolean>(false)
  const [initialAssetData, setInitialAssetData] = useState<Asset | null>(null)

  const ledgerSettings: LedgerSetting[] = useSelector(selectLedgerSettingList, isEqual)
  const terms = useSelector(selectTermsData, isEqual)
  const tempAssetData = useSelector(selectTempDataData, isEqual)
  const errors = useSelector(selectAssetItemErrors, isEqual)
  const loading = useSelector(selectAssetItemLoading, isEqual)
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={i18n.t('pages.assets.add')} />

  useEffect(() => {
    dispatch(fetchTempData(TempDataTypes.CREATE_ASSET))
    !terms && dispatch(fetchTerms())
    dispatch(fetchLedgerSettingsList())
  }, []) // eslint-disable-line

  useEffect(() => {
    return function cleanup() {
      dispatch(clearTempData())
      dispatch(cleanupAssetItem())
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (tempAssetData) {
      setConfirmVisible(true)
    }
  }, [tempAssetData])

  useEffect(() => {
    if (ledgerSettings && terms) {
      const newInitialAssetData = { ...defaultAssetValues }
      newInitialAssetData.fixed_asset_ledgers = initialAssetLedgersFromSettings(ledgerSettings)
      newInitialAssetData.min_acquired_at = terms[0]?.start_date
      setInitialAssetData(newInitialAssetData)
    }
  }, [ledgerSettings, terms])

  const handleCancelConfirm = () => {
    dispatch(clearTempData())
    setConfirmVisible(false)
    setIsAppliedTemporary(false)
  }

  const handleOkConfirm = () => {
    tempAssetData &&
      setInitialAssetData({
        ...tempAssetData,
        fixed_asset_ledgers: mergeAssetLedgersWithLedgerSettings(tempAssetData.fixed_asset_ledgers, ledgerSettings),
      })
    setConfirmVisible(false)
    setIsAppliedTemporary(true)
  }
  return {
    initialAssetData,
    confirmVisible,
    handleCancelConfirm,
    handleOkConfirm,
    isAppliedTemporary,
    errors,
    loading,
    breadcrumb,
  }
}
