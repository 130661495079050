import { ErrorsList, ExportModal, ImportModal } from 'aa_common/front-end/components'
import { BreadcrumbNavigation } from 'components/molecules'
import DepartmentNewList from 'components/organisms/settings/DepartmentNewList'
import { ListPageTemplate } from 'components/templates'
import withRouterTrap from 'components/withRouterTrap'
import i18n from 'i18n'
import React from 'react'
import { useDispatch } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { cleanupDepartment } from 'store/settings/department/actions'

import { RefactorWarningMessage } from '../../../common/components/refactor-warning-message'
import { useDepartmentsList } from './hooks/use-departments-list'

const breadcrumb = <BreadcrumbNavigation sectionName={i18n.t('pages.settings.Departments.list')} />

const DepartmentList = withRouterTrap(() => {
  const {
    filterPanel,
    departmentList,
    errorsList,
    importModalContent,
    exportModalContent,
    isShowExportModal,
    handleImport,
    preImportErrs,
    handleExport,
    mainFilters,
    departmentListLoading,
    importLoading,
    filters,
    sort,
    pageNumber,
    totalPages,
    totalRecords,
    setIsShowExportModal,
    dispatch,
    setFilters,
    setSort,
    setPageNumber,
    importModalRef,
  } = useDepartmentsList()
  return (
    <>
      <RefactorWarningMessage />
      <ListPageTemplate
        breadcrumb={breadcrumb}
        maxWidth={1200}
        filterPanel={filterPanel}
        errors={(preImportErrs && <ErrorsList errors={preImportErrs} />) || errorsList}
      >
        <DepartmentNewList
          ref={mainFilters}
          loading={departmentListLoading || importLoading}
          departments={departmentList}
          initFormValues={filters}
          initSortState={sort}
          currentPage={pageNumber}
          totalPages={totalPages}
          totalRecords={totalRecords}
          onExportClick={() => setIsShowExportModal(true)}
          onImportClick={handleImport}
          onAddNew={() => dispatch(changeLocation('/master/departments/new'))}
          onCopy={item => dispatch(changeLocation(`/master/departments/${item.id}/copy`))}
          onFiltersChanged={newFilters => setFilters({ ...filters, ...newFilters })}
          onSortsChanged={setSort}
          onPageChanged={setPageNumber}
        />
        <ExportModal
          title={i18n.t('common.form_values.export_modal_title', {
            name: i18n.t('components.DepartmentList.department'),
          })}
          isShow={isShowExportModal}
          onCancel={() => setIsShowExportModal(false)}
          onExport={handleExport}
        >
          {exportModalContent}
        </ExportModal>
        <ImportModal
          ref={importModalRef}
          title={i18n.t('common.form_values.import_modal_title', {
            name: i18n.t('components.DepartmentList.department'),
          })}
        >
          {importModalContent}
        </ImportModal>
      </ListPageTemplate>
    </>
  )
})

export const DepartmentListContainer = () => {
  const dispatch = useDispatch()
  return <DepartmentList path="/master/departments" onLeave={() => dispatch(cleanupDepartment())} />
}
