import { ErrorsList } from 'aa_common/front-end'
import { CsvCharset } from 'common/models'
import { RenderFormProps } from 'components/molecules/Form'
import DepartmentFilter from 'components/organisms/settings/DepartmentFilter'
import { getInitFilters } from 'components/organisms/settings/DepartmentNewList'
import { useParserHtmlFromI8n } from 'hooks/useParserHtmlFromI8n'
import i18n from 'i18n'
import useFiltersSortsPaging from 'lib/hooks/useFiltersSortsPaging'
import { isEqual } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'
import { exportDepartments, fetchDepartmentsAuth, importDepartments } from 'store/settings/department/actions'
import { selectDepartmentList, selectImport } from 'store/settings/department/selectors'
import { useDebouncedCallback } from 'use-debounce'

export const useDepartmentsList = () => {
  const dispatch = useDispatch()
  const {
    departmentList,
    departmentListLoading,
    departmentFilterConditions: initFilters,
    departmentSortConditions: initSort,
    pageNumber: initPageNumber,
    totalRecords,
  } = useSelector(selectDepartmentList, isEqual)
  const masterData = useSelector(selectMasterData, isEqual)
  const { importError, loading: importLoading } = useSelector(selectImport, isEqual)

  const [isShowExportModal, setIsShowExportModal] = useState(false)

  const fetchDepartments = useDebouncedCallback((filters, sort, pageNumber, pageSize) => {
    dispatch(fetchDepartmentsAuth({ filters, sort, pageNumber, pageSize }))
  }, 100)
  const { filters, setFilters, sort, setSort, pageNumber, setPageNumber, pageSize, totalPages } = useFiltersSortsPaging(
    {
      totalRecords,
      initFilters: getInitFilters(initFilters),
      initSort,
      initPageNumber,
    }
  )
  useEffect(() => {
    fetchDepartments(filters, sort, pageNumber, pageSize)
  }, [fetchDepartments, filters, sort, pageNumber, pageSize])

  const mainFilters = useRef<RenderFormProps>(null)
  const searchPanelFilters = useRef<HTMLFormElement>(null)
  const importModalRef = useRef<any>(null)
  const [preImportErrs, setPreImportErrs] = useState<null>()
  const handleImport = () => {
    importModalRef.current?.open({
      onValuesCb(params: any) {
        const { charset, file } = params || {}
        setPreImportErrs(file ? null : params)
        if (!file) return

        Promise.resolve(dispatch(importDepartments(charset, file))).then(() => {
          const initFilters = getInitFilters(filters)
          searchPanelFilters.current?.reset()
          mainFilters.current?.reset(initFilters)
          setFilters(initFilters, true)
        })
      },
    })
  }

  const handleExport = (charset: CsvCharset) => {
    setIsShowExportModal(false)
    dispatch(exportDepartments(charset, filters))
  }

  const errorsList =
    importError.message || importError?.details?.length ? (
      <ErrorsList
        title={importError.message || i18n.t('common.messages.can_not_import')}
        errors={importError.details}
      />
    ) : null

  const filterPanel = (
    <DepartmentFilter
      ref={searchPanelFilters}
      loading={departmentListLoading || importLoading}
      prepressCategories={masterData.prepress_category}
      onSubmit={values => setFilters({ ...filters, ...values })}
    />
  )
  const exportModalContent = useParserHtmlFromI8n('common.form_values.export_modal_content', {
    name: i18n.t('components.DepartmentList.department'),
    returnObjects: true,
  }).content
  const importModalContent = useParserHtmlFromI8n('common.form_values.import_modal_content', {
    name: i18n.t('components.DepartmentList.department'),
    fileUrl: `${process.env.PUBLIC_URL}/files/import_samples/department_sample.csv`,
    fileName: 'department_sample.csv',
    returnObjects: true,
  }).content

  return {
    exportModalContent,
    filterPanel,
    handleExport,
    handleImport,
    importModalContent,
    isShowExportModal,
    preImportErrs,
    departmentList,
    errorsList,
    mainFilters,
    departmentListLoading,
    importLoading,
    masterData,
    setFilters,
    setSort,
    setPageNumber,
    totalPages,
    pageSize,
    sort,
    pageNumber,
    filters,
    dispatch,
    totalRecords,
    setIsShowExportModal,
    importModalRef,
  }
}
