import { LinkLabel } from 'aa_common/front-end/components'
import { BreadcrumbNavigation } from 'components/molecules'
import { AssetImportForm } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import i18n from 'i18n'
import { isEqual } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { selectLoadingApp } from 'store/app/selectors'

const AssetImportPage: React.FC = () => {
  const dispatch = useDispatch()

  const isLoadingApp = useSelector(selectLoadingApp, isEqual)

  const handleCancel = () => {
    dispatch(changeLocation('/assets/'))
  }

  const cancelLink = (
    <LinkLabel onClick={handleCancel} style={{ fontSize: 13, paddingRight: '15px' }}>
      {i18n.t('common.actions.cancel')}
    </LinkLabel>
  )

  const breadcrumb = (
    <BreadcrumbNavigation
      sectionName={i18n.t('components.AssetImportForm.csv_register')}
      customBackElement={cancelLink}
    />
  )

  return (
    <MainTemplate breadcrumb={breadcrumb} isLoadingApp={isLoadingApp}>
      <AssetImportForm fromPath="assets/import" />
    </MainTemplate>
  )
}

export default AssetImportPage
