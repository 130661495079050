import { everyTrue, ifElse } from 'aa_common/front-end'
import { CONFIRM_TYPES } from 'common/constants'
import { getNs } from 'common/i18n-config'
import { ExciseSetting } from 'common/models'
import useModal from 'hooks/useModalHook'
import { useCallback } from 'react'
import { deleteExciseSetting } from 'store/settings/exciseSetting/actions'

const { t, useNsReady } = getNs(['components/organisms/settings/excise-setting-view'])
export const useHandleDelete = (dispatch: any) => {
  useNsReady()
  const modal = useModal()
  return useCallback(
    ({ from_term, to_term, id }: ExciseSetting) => {
      const fromYear = from_term?.year
      const toYear = to_term?.year
      const i18nKey = ifElse(everyTrue(!!fromYear, !!toYear, fromYear !== toYear), 'dual', 'single')
      modal
        .confirm({
          title: t(`confirm_delete_modal.title.${i18nKey}`, { fromYear, toYear }),
          message: t(`confirm_delete_modal.content.${i18nKey}`, { fromYear, toYear }),
          type: CONFIRM_TYPES.DELETE,
        })
        .then(isConfirmed => {
          isConfirmed && dispatch(deleteExciseSetting(id))
        })
    },
    [modal, dispatch]
  )
}
