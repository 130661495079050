import { handleErrorResponse } from 'common/utils/response-helper'
import { validateRequiredField } from 'components/organisms/assets/AssetForm/helper'
import { getGeneralValidationErrors } from 'components/organisms/assets/AssetForm/validations/asset-schema'

export const removeImpairInfoLedger = (assetItem: any) => {
  assetItem?.fixed_asset_ledgers?.map((ledger: any) => {
    delete ledger?.accumulated_impaired_amount
    return ledger
  })
  return assetItem
}
export const checkError = (assetItem: any, setShowRequiredError: Function) => {
  if (validateRequiredField(assetItem) || !!getGeneralValidationErrors().join(',')) {
    setShowRequiredError(true)
    return true
  }
  return false
}

export const handleResponse = (res: any, setValidateErrors: any) => {
  if (res.error) {
    handleErrorResponse(
      {
        response: {
          status: res.status,
          data: { errors: res.error },
        },
      },
      setValidateErrors
    )
  }
}
