import { FilterFunction, initAsyncSlice } from 'aa_common/front-end/zustand'
import { OfficeUsersService, PresenterOneOfficeUserResponse } from 'common/open-api'
import { parseErrorResponse } from 'common/utils/response-helper'

const initFilter = {}
const initState: { officeUsers: PresenterOneOfficeUserResponse[] } = {
  officeUsers: [],
}

const getOfficeUsers: FilterFunction<typeof initState, typeof initFilter> = async () => {
  try {
    const officeUsers = (await OfficeUsersService.officeUsersList())?.data
    return { data: { officeUsers } }
  } catch (err: any) {
    parseErrorResponse(err)
    return { data: { officeUsers: [] } }
  }
}

export const officeUsersSlice = initAsyncSlice<typeof initState, typeof initFilter>(
  'settings/users',
  initState,
  initFilter,
  getOfficeUsers
)

export const triggerGetOfficeUsers = () => {
  officeUsersSlice.setFilters({}, true)
}

export const resetOfficeUsers = () => officeUsersSlice.setData(initState)
