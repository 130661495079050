import { ifElse } from 'aa_common/front-end'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { ReCalculateContainer } from 'containers/settings/re-calculate-container/re-calculate-container'
import React from 'react'

import { ReCalculatePageOld } from './re-calculate-page'

const ReCalculatePage = () => {
  const { isEnable: isRefactorPagePhase7 } = useCheckFlag(FeatureFlagNames.RefactorPagePhase7)
  return <>{ifElse(isRefactorPagePhase7, <ReCalculateContainer />, <ReCalculatePageOld />)}</>
}

export default ReCalculatePage
