import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { DepartmentCopyContainer } from 'containers/settings/department-copy-container'
import React from 'react'

import { DepartmentCopyPageWrapperOld } from './department-copy-page'

const DepartmentCopyPage = () => {
  const { isEnable: isRefactorPagePhase6 } = useCheckFlag(FeatureFlagNames.RefactorPagePhase6)
  return <>{isRefactorPagePhase6 ? <DepartmentCopyContainer /> : <DepartmentCopyPageWrapperOld />}</>
}

export default DepartmentCopyPage
