import { ErrorsList, ExportModal, ImportModal } from 'aa_common/front-end/components'
import { CsvCharset } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import { ListPageTemplate } from 'components/templates'
import { CauseList } from 'containers/master/causes/cause-list-container/components'
import { useParserHtmlFromI8n } from 'hooks/useParserHtmlFromI8n'
import i18n from 'i18n'
import { get } from 'immutable'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { cleanupCause, deleteCause, exportCauses, fetchCauseListAuth, importCauses } from 'store/settings/cause/actions'

type Props = {
  causes: any[]
  listLoading?: boolean
  importError: any
  masterData: any[]
  fetchCauseListAuth: typeof fetchCauseListAuth
  deleteCause: typeof deleteCause
  exportCauses: typeof exportCauses
  importCauses: typeof importCauses
  cleanupCause: typeof cleanupCause
  changeLocation: typeof changeLocation
}

const CauseListPageOld = ({
  causes,
  listLoading,
  importError,
  masterData,
  fetchCauseListAuth,
  deleteCause,
  exportCauses,
  importCauses,
  cleanupCause,
  changeLocation,
}: Props) => {
  const [isShowExportModal, setIsShowExportModal] = useState(false)

  const breadcrumb = <BreadcrumbNavigation sectionName={i18n.t('pages.settings.Causes.list')} />

  useEffect(() => {
    fetchCauseListAuth()
    return cleanupCause
  }, [fetchCauseListAuth, cleanupCause])

  const handleAddNew = () => {
    changeLocation('/master/causes/new')
  }

  const handleExport = (charset: CsvCharset) => {
    setIsShowExportModal(false)
    exportCauses(charset)
  }

  const importModalRef = useRef<any>(null)
  const [preImportErrs, setPreImportErrs] = useState<null>()
  const handleImportClick = () => {
    importModalRef.current?.open({
      onValuesCb(params: any) {
        const { charset, file } = params || {}
        setPreImportErrs(file ? null : params)
        if (!file) return

        importCauses(charset, file)
      },
    })
  }

  const handleCopy = (item: any) => {
    changeLocation(`/master/causes/${item.id}/copy`)
  }

  const handleEdit = (item: any) => {
    changeLocation(`/master/causes/${item.id}/edit`)
  }

  const handleDelete = (item: any) => {
    deleteCause(item)
  }

  const handleExportClick = () => setIsShowExportModal(true)

  const errorsList = (importError.message || get(importError, 'details', []).length > 0) && (
    <ErrorsList title={importError.message || i18n.t('common.messages.can_not_import')} errors={importError.details} />
  )
  const exportModalContent = useParserHtmlFromI8n('common.form_values.export_modal_content', {
    name: i18n.t('components.CauseList.cause'),
    returnObjects: true,
  }).content

  const importModalContent = useParserHtmlFromI8n('common.form_values.import_modal_content', {
    name: i18n.t('components.CauseList.cause'),
    fileUrl: `${process.env.PUBLIC_URL}/files/import_samples/cause_sample.csv`,
    fileName: 'cause_sample.csv',
    returnObjects: true,
  }).content

  return (
    <ListPageTemplate
      maxWidth={700}
      breadcrumb={breadcrumb}
      errors={(preImportErrs && <ErrorsList errors={preImportErrs} />) || errorsList}
    >
      <CauseList
        causes={causes}
        masterData={masterData}
        onCopy={handleCopy}
        onEdit={handleEdit}
        onDelete={handleDelete}
        handleAddNew={handleAddNew}
        handleExportClick={handleExportClick}
        handleImportClick={handleImportClick}
        loading={listLoading}
      />
      <ExportModal
        title={i18n.t('common.form_values.export_modal_title', { name: i18n.t('components.CauseList.cause') })}
        isShow={isShowExportModal}
        onCancel={() => setIsShowExportModal(false)}
        onExport={handleExport}
      >
        {exportModalContent}
      </ExportModal>
      <ImportModal
        ref={importModalRef}
        title={i18n.t('common.form_values.import_modal_title', { name: i18n.t('components.CauseList.cause') })}
      >
        {importModalContent}
      </ImportModal>
    </ListPageTemplate>
  )
}

const mapStateToProps = (state: Readonly<any>) => ({
  causes: state.getIn(['settings', 'cause', 'list', 'data']) || [],
  listLoading: state.getIn(['settings', 'cause', 'list', 'loading']),
  importError: state.getIn(['settings', 'cause', 'import', 'error'], {}),
  masterData: state.getIn(['masterData', 'data', 'cause_type']),
})

const mapDispatchToProps = {
  fetchCauseListAuth,
  deleteCause,
  cleanupCause,
  exportCauses,
  importCauses,
  changeLocation,
}

export default connect(mapStateToProps, mapDispatchToProps)(CauseListPageOld)
