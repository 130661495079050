import { ifElse } from 'aa_common/front-end'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import React from 'react'

import { AssetDetailContainer } from '../../../containers/assets/asset-detail-container'
import AssetDetailPageOld from './asset-detail-page'

const AssetDetailPage = () => {
  const { isEnable: isRefactorAssetDetailPage } = useCheckFlag(FeatureFlagNames.RefactorPagePhase5)
  return <>{ifElse(isRefactorAssetDetailPage, <AssetDetailContainer />, <AssetDetailPageOld />)}</>
}

export default AssetDetailPage
