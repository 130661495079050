import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { AssetCodeStartContainer } from 'containers/settings/asset-code-start-number-container/asset-code-start-container'
import React from 'react'

import { AssetCodeStartNumberPageOld } from './asset-code-start-number-page'

const AssetCodeStartNumberPage: React.FC = () => {
  const { isEnable: isEnableAssetCodeStartNumberPage } = useCheckFlag(FeatureFlagNames.RefactorPagePhase5)

  return <>{isEnableAssetCodeStartNumberPage ? <AssetCodeStartContainer /> : <AssetCodeStartNumberPageOld />}</>
}

export default AssetCodeStartNumberPage
