import { AssetCodeStartNumberForm } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import React from 'react'

import { useAssetCodeStart } from './hooks/use-asset-code-start'

export const AssetCodeStartContainer: React.FC = () => {
  const { breadcrumb } = useAssetCodeStart()
  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <AssetCodeStartNumberForm />
    </MainTemplate>
  )
}
