import { messageSuccess } from 'aa_common/front-end'
import { buildQueryFilterParams } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { ExportService } from 'common/open-api'
import { useLongPolling } from 'hooks/useLongPolling'

import { CsvFormat, ExportType } from '../constant'

type Props = {
  handleOpenModal: (visible: boolean) => void
}
const { t } = getNs(['pages/accounting/depreciation-fixed-asset-page'])

export const onSuccess = (result: any) => {
  if (result?.data?.url) {
    messageSuccess(t('export_modal.outputted_the_file'))
    window.open(result?.data?.url, '_blank')
  }
}

export const useExportCsv = ({ handleOpenModal }: Props) => {
  const exportCsvTaxDeclarationPolling = useLongPolling('export-tax-declaration-depreciation-csv', {
    onSuccess,
    onError: () => {},
  })

  const handleExportCsv = async (data: ExportCsvMultileDto) => {
    const { submit_destination_ids, ...filterParams } = data

    const filters = buildQueryFilterParams(filterParams)
    try {
      const response: any = await ExportService.fixedAssetsEltaxMultipleList({
        ...filters,
        submit_destination_ids,
      })
      exportCsvTaxDeclarationPolling.setJobId(response?.data?.id)
      handleOpenModal(false)
    } catch (error) {
      handleOpenModal(false)
    }
  }

  return {
    handleExportCsv,
    exportCsvTaxDeclarationPolling,
  }
}

export type ExportCsvMultileDto = {
  /** export type: all, summary, decrease, increase (1,2,3,4) */
  type: number | string
  /** IDs array of submit destinations */
  submit_destination_ids: any
  /** fiscal year of depreciable fixed asset tax declaration */
  fiscal_year: number | string

  path: string
  /** mode of export: 1: all, 2: in_fiscal_year */
  mode: number | string
  exportType?: ExportType
  charset: CsvFormat
}
