import { LinkLabel } from 'aa_common/front-end'
import { loadNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { fetchAutoGenerateSettings } from 'store/settings/assetCode/actions'
import { selectAssetCodeAutoGenerateSettingData } from 'store/settings/assetCode/selectors'

const t = loadNs(['common', 'pages/settings/asset-code'])

export const useAssetCodeStart = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const assetCodeAutoGenerateSettingData = useSelector(selectAssetCodeAutoGenerateSettingData, isEqual)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)

  const clickCancel = useCallback(() => {
    history.push('/setting/asset-codes/auto-generate')
  }, [history])

  const cancelLink = useMemo(
    () => (
      <LinkLabel id="cancel-monthly-closing-setting" style={{ fontSize: 13, marginRight: 15 }} onClick={clickCancel}>
        {t('actions.cancel')}
      </LinkLabel>
    ),
    [clickCancel]
  )

  const breadcrumb = (
    <BreadcrumbNavigation
      enableBackButton={false}
      sectionName={t('start_number_setting')}
      customBackElement={cancelLink}
    />
  )

  useEffect(() => {
    !assetCodeAutoGenerateSettingData && dispatch(fetchAutoGenerateSettings())
    !currentMonthlyClosing && dispatch(fetchMonthlyClosingCurrent())
  }, []) // eslint-disable-line
  return {
    breadcrumb,
    cancelLink,
    clickCancel,
  }
}
