import { useEffect } from 'react'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { fetchTerms } from 'store/settings/accountingPeriod/actions'
import { fetchExciseSettingAuth } from 'store/settings/exciseSetting/actions'

export const useFetchData = (dispatch: any) => {
  useEffect(() => {
    dispatch(fetchTerms())
    dispatch(fetchMonthlyClosingCurrent())
    dispatch(fetchExciseSettingAuth())
  }, [dispatch])
}
