import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { DepartmentListContainer } from 'containers/master/department-list-container'
import React from 'react'

import { DepartmentListPageOld } from './department-list-page'

const DepartmentListPage = () => {
  const { isEnable: isRefactorCauseNewPage } = useCheckFlag(FeatureFlagNames.RefactorPagePhase6)

  return <>{isRefactorCauseNewPage ? <DepartmentListContainer /> : <DepartmentListPageOld />}</>
}
export default DepartmentListPage
