import { AssetForm, AssetTemporaryDataModal } from 'components/organisms'
import { MainTemplate } from 'components/templates'

import { useAssetNewActions } from './hooks/use-asset-new-actions'
import { useFetchDataAndConfirm } from './hooks/use-fetch-data-and-confirm'

export const AssetNewContainer = () => {
  const {
    initialAssetData,
    confirmVisible,
    loading,
    errors,
    handleCancelConfirm,
    handleOkConfirm,
    isAppliedTemporary,
    breadcrumb,
  } = useFetchDataAndConfirm()
  const {
    isValidateAssetLoading,
    asset,
    validateErrors,
    handleSaveTemp,
    handleSubmit,
    tempAssetData,
    showRequiredError,
  } = useAssetNewActions()

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <AssetForm
        initialAssetData={initialAssetData}
        loading={loading || isValidateAssetLoading}
        errors={validateErrors || errors}
        onSubmit={handleSubmit}
        confirming={confirmVisible}
        onSaveTemp={handleSaveTemp}
        showRequiredError={showRequiredError}
        asset={asset}
        isTemporaryData={isAppliedTemporary}
      />
      {tempAssetData && (
        <AssetTemporaryDataModal
          isShow={confirmVisible}
          tempAssetData={tempAssetData}
          onCancel={handleCancelConfirm}
          onOk={handleOkConfirm}
        />
      )}
    </MainTemplate>
  )
}
