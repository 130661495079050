import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { ImportContainer } from 'containers/settings/import-container'
import React from 'react'

import { SettingImportPageOld } from './import-page'

const SettingImportPage = () => {
  const { isEnable: isRefactorPagePhase7 } = useCheckFlag(FeatureFlagNames.RefactorPagePhase7)
  return isRefactorPagePhase7 ? <ImportContainer /> : <SettingImportPageOld />
}

export default SettingImportPage
