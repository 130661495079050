import { isEqual } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchRoleDetail } from 'store/settings/role/actions'
import { selectRoleDetail } from 'store/settings/role/selectors'

export const useFetchRoleEdit = () => {
  const { biid } = useParams<any>()
  const dispatch = useDispatch()

  const { data, loading } = useSelector(selectRoleDetail, isEqual)

  useEffect(() => {
    dispatch(fetchRoleDetail(biid))
  }, [])
  return { data, loading, biid }
}
