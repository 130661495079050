import { Tooltip } from 'antd'
import { DATE_FORMAT, DATE_FORMAT_RFC, DEPRECIATION_METHOD_CODE } from 'common/constants'
import { getDepreciationCodeOfAccountingLedger } from 'common/helpers/ledger-helper'
import { Asset, ASSET_ITEM_STATUS_PARAM_TYPE, AssetSingleViewQueryParams } from 'common/models'
import i18n from 'i18n'
import { get } from 'lodash'
import { Moment } from 'moment'
import moment from 'moment/moment'
import queryString from 'query-string'
import { ComponentProps } from 'react'

import { RegisterType } from './schema'

type AntTooltipProps = ComponentProps<typeof Tooltip>

export const disableReasonTooltip: AntTooltipProps = { placement: 'top', title: i18n.t('common.actions.no_permission') }

export function isInapplicableForImpairment(asset: Asset) {
  const dMethod = getDepreciationCodeOfAccountingLedger(asset?.fixed_asset_ledgers)
  return (
    dMethod &&
    [DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET, DEPRECIATION_METHOD_CODE.NON_DEPRECIATION].includes(
      dMethod
    )
  )
}

function getTooltipMsg(hasPermission: boolean, asset: any) {
  if (!hasPermission) return disableReasonTooltip.title

  if (asset.impaired_at) return i18n.t('components.AssetImpairment.tooltips.inapplicable')

  return ''
}

export function getImpairmentMenuSettingMemo(hasPermission: boolean, asset: any, isRetiredOrSold: boolean) {
  if (isRetiredOrSold) {
    return {
      disabled: true,
    }
  }
  const title = getTooltipMsg(hasPermission, asset)
  if (title) {
    return {
      disabled: true,
      tooltip: { ...disableReasonTooltip, title },
    }
  }
  return {}
}
export function getAssetSingleViewQueryParams(searchQuery: string) {
  const { valid_at, type = '' } = queryString.parse(searchQuery) as AssetSingleViewQueryParams
  const validAt = moment(valid_at).isValid() ? moment(valid_at) : moment()

  return {
    type,
    validAt,
    valid_at: validAt.format(DATE_FORMAT_RFC),
  }
}

export function getIsRetiredOrSold(asset: Asset): boolean {
  return !!get(asset, 'retired_at') || !!get(asset, 'sold_at')
}
export function renderDisplayDateMessage(valid_at: moment.Moment, type?: string, dataByValidAt?: Asset) {
  if (!valid_at) return null

  const isToBeAcquired = type === ASSET_ITEM_STATUS_PARAM_TYPE.TO_BE_ACQUIRED
  const filterDate = moment(isToBeAcquired ? dataByValidAt?.acquired_at : valid_at)
  const context = !isToBeAcquired && moment().isSame(filterDate, 'date') ? 'today' : ''
  return i18n.t('components.AssetDetail.filter_at_fixed_asset', {
    context,
    filterDate: filterDate.format(DATE_FORMAT),
  })
}

export function getEventKey(dataByValidAt: Asset) {
  let eventKey: string | null = null
  if (dataByValidAt.sold_at) {
    eventKey = 'sold'
  } else if (dataByValidAt.retired_at) {
    eventKey = 'retired'
  } else if (dataByValidAt.impaired_at) {
    eventKey = 'impaired'
  }
  return eventKey
}

export function getMessage(
  dataByValidAt: Asset,
  validAt: Moment,
  registerEvent: string,
  acquiredAt: string,
  eventAt: Moment,
  type: string
) {
  let message: string | null = null
  if (type === ASSET_ITEM_STATUS_PARAM_TYPE.TO_BE_ACQUIRED && validAt.isSameOrBefore(acquiredAt, 'date')) {
    message = i18n.t('components.AssetDetail.to_be_acquired_display_box_message', { acquiredAt })
  } else if (validAt.isSameOrAfter(eventAt, 'date')) {
    const eventKey = getEventKey(dataByValidAt)
    message = eventKey ? i18n.t(`components.AssetDetail.asset_has_been_${eventKey}`) : ''
  }
  if (registerEvent && registerEvent !== RegisterType.DIVIDED) {
    message = i18n.t(`components.AssetDetail.asset_has_been_${registerEvent}`)
  }
  return message
}

export function checkShowForm(conditions: boolean[]) {
  return conditions.some(Boolean)
}
