import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'aa_common/front-end'
import { getNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import React from 'react'

const { t, useNsReady } = getNs(['pages/settings/excise-setting'])

export const BreadCrumb = () => {
  useNsReady()
  return (
    <BreadcrumbNavigation
      enableBackButton={false}
      sectionName={
        <div style={{ display: 'flex' }}>
          <span>{t('excise_setting')}</span>
          <Tooltip content={t('tooltip.excise_setting')} tooltipStyles={{ marginLeft: 5 }}>
            <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }} />
          </Tooltip>
        </div>
      }
    />
  )
}
