import { loadNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import ExciseList from 'components/organisms/settings/ExciseList'
import { ListPageTemplate } from 'components/templates'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchExciseListAuth } from 'store/settings/excise/actions'
import { getExciseListSelector } from 'store/settings/excise/selectors'

const t = loadNs(['pages/settings/excise'])

const ExciseListPageOld = () => {
  const dispatch = useDispatch()
  const exciseListSelector = useSelector(getExciseListSelector)

  useEffect(() => {
    dispatch(fetchExciseListAuth({ sort: ['id'] }))
  }, [dispatch])

  const handleOnReload = () => {
    dispatch(fetchExciseListAuth({}))
  }

  const breadcrumb = <BreadcrumbNavigation sectionName={t('list')} />

  return (
    <ListPageTemplate breadcrumb={breadcrumb}>
      <ExciseList data={exciseListSelector.data} loading={exciseListSelector.isLoading} onReload={handleOnReload} />
    </ListPageTemplate>
  )
}

export default ExciseListPageOld
