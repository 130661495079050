import { CauseItem } from 'common/models'

export const selectCauseItem = (state: any) => {
  return {
    loading: state.getIn(['settings', 'cause', 'item', 'loading']) as boolean,
    data: state.getIn(['settings', 'cause', 'item', 'data']) as CauseItem,
    errors: state.getIn(['settings', 'cause', 'item', 'errors']),
  }
}

export const selectCauseList = (state: any) => {
  return {
    loading: state.getIn(['settings', 'cause', 'list', 'loading']) as boolean,
    data: (state.getIn(['settings', 'cause', 'list', 'data']) as CauseItem[]) || [],
    listLoading: state.getIn(['settings', 'cause', 'list', 'loading']),
    importError: state.getIn(['settings', 'cause', 'import', 'error'], {}),
  }
}
