import { all } from 'redux-saga/effects'

import accountingPeriod from './accountingPeriod/saga'
import accountItem from './accountItem/saga'
import area from './area/saga'
import assetCategory from './assetCategory/saga'
import assetCode from './assetCode/saga'
import cause from './cause/saga'
import department from './department/saga'
import excise from './excise/saga'
import exciseSetting from './exciseSetting/saga'
import ledgerSetting from './ledgerSetting/saga'
import role from './role/saga'
import submitDestination from './submitDestination/saga'

export default function* settingsSaga() {
  yield all([
    assetCategory(),
    area(),
    excise(),
    submitDestination(),
    cause(),
    department(),
    accountingPeriod(),
    accountItem(),
    ledgerSetting(),
    assetCode(),
    exciseSetting(),
    role(),
  ])
}
