import { ifElse } from 'aa_common/front-end'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { RoleEditContainer } from 'containers/settings/role-edit-container/role-edit-container'
import React from 'react'

import { RoleEditPageOld } from './role-edit-page'

const RoleEditPage = () => {
  const { isEnable: isRefactorPagePhase7 } = useCheckFlag(FeatureFlagNames.RefactorPagePhase7)
  return <>{ifElse(isRefactorPagePhase7, <RoleEditContainer />, <RoleEditPageOld />)}</>
}

export default RoleEditPage
