import { ASSET_CHANGE_SITUATION_TYPES } from 'common/constants'
import { RequestState } from 'lib/hooks/useResource'
import get from 'lodash/get'
import { useEffect } from 'react'

export const useShowEvent = (
  responseAssetEvent: RequestState,
  setCurrentAssetEvent: Function,
  setIsShowUpdateUsageStartedForm: Function,
  setIsShowUpdateRetirementForm: Function,
  setIsShowUpdateMovementForm: Function,
  setIsShowUpdateSellForm: Function
) => {
  useEffect(() => {
    if (responseAssetEvent.data) {
      setCurrentAssetEvent(responseAssetEvent.data)
      const event = new Map([
        [ASSET_CHANGE_SITUATION_TYPES.USAGE_STARTED, setIsShowUpdateUsageStartedForm],
        [ASSET_CHANGE_SITUATION_TYPES.RETIREMENT, setIsShowUpdateRetirementForm],
        [ASSET_CHANGE_SITUATION_TYPES.MOVEMENT, setIsShowUpdateMovementForm],
        [ASSET_CHANGE_SITUATION_TYPES.SELL, setIsShowUpdateSellForm],
      ]).get(get(responseAssetEvent.data, ['change_situation', 'code']))
      event && event(true)
    }
  }, [responseAssetEvent])
}
