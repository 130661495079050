import * as assetApi from 'api/app/asset'
import { ASSET_CHANGE_SITUATION_TYPES, FEATURES_ID } from 'common/constants'
import { checkRetroActiveFixedAsset, hasEventOccurred, isRetroactiveEvent } from 'common/helpers/asset-helper'
import { handlePermissionByFeatureId } from 'common/helpers/permission-helper'
import { ASSET_ITEM_STATUS_PARAM_TYPE, AssetSingleViewQueryParams, FORM_MODES } from 'common/models'
import { DropdownMenuSettings } from 'components/molecules/DropdownMenuButton'
import {
  AssetDetail,
  AssetEventSellForm,
  AssetImpairmentForm,
  AssetModificationForm,
  AssetMovementForm,
  AssetRetirementForm,
  AssetUsageStartedForm,
  DividingForm,
} from 'components/organisms'
import { MainTemplate } from 'components/templates'
import useRetrospectiveAssetModal from 'components/templates/modal/RetroactiveAssetModal'
import useResource from 'lib/hooks/useResource'
import { get, isEqual } from 'lodash'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'
import {
  clearAssetEventErrors,
  createAssetEventMovement,
  createAssetEventRetirement,
  createAssetEventSell,
  createAssetEventUsage,
  fetchAssetByValidAt,
  submitModifiedAssetType,
  updateAssetEventMovement,
  updateAssetEventRetirement,
  updateAssetEventSell,
  updateAssetEventUsage,
} from 'store/asset/actions'
import { selectAssetDetailPage, selectAssetItemByValidAt, selectEventHistories } from 'store/asset/selectors'
import { selectUserPermission } from 'store/session/selectors'
import { fetchCauseList } from 'store/settings/cause/actions'

import { TopMessage } from './components/top-message'
import { EVENT_TYPES } from './constants'
import { checkShowForm, getIsRetiredOrSold } from './helpers'
import {
  useFetchInitData,
  useGetDatabyValidAt,
  useGetDropdownMenuSetting,
  useRenderBreadcrumb,
  useShowEvent,
} from './hooks'
import { RegisterType } from './schema'
import { ContainerClass, Section } from './styles'

export const AssetDetailContainer = () => {
  const { biid } = useParams<{ biid: string }>()
  const dispatch = useDispatch()
  const location = useLocation()
  const confirmModal = useRetrospectiveAssetModal()
  const [registerEvent, setRegisterEvent] = useState('')
  const [currentAssetEvent, setCurrentAssetEvent] = useState<any>(null)

  const [isShowImpairmentForm, setShowImpairmentForm] = useState(false)
  const [isShowUsageStartedForm, setIsShowUsageStartedForm] = useState<boolean>(false)
  const [isShowRetirementForm, setIsShowRetirementForm] = useState<boolean>(false)
  const [isShowMovementForm, setIsShowMovementForm] = useState<boolean>(false)
  const [isShowUpdateUsageStartedForm, setIsShowUpdateUsageStartedForm] = useState<boolean>(false)
  const [isShowUpdateRetirementForm, setIsShowUpdateRetirementForm] = useState<boolean>(false)
  const [isShowUpdateMovementForm, setIsShowUpdateMovementForm] = useState<boolean>(false)
  const [isShowSellForm, setIsShowSellForm] = useState<boolean>(false)
  const [isShowUpdateSellForm, setIsShowUpdateSellForm] = useState<boolean>(false)
  const [isShowTypeModifyForm, setIsShowTypeModifyForm] = useState(false)
  const [isShowDividingForm, setIsShowDividingForm] = useState<boolean>(false)

  const [responseAssetEvent, getCurrentAssetEvent] = useResource(assetApi.getById)
  const [, validateUsagedAt] = useResource(assetApi.createUsage)
  const [isShowConfirmMessage, setIsShowConfirmMessage] = React.useState(false)

  const { asset, itemHistoryLoading, itemHistoryErrors, causes } = useSelector(selectAssetDetailPage, isEqual)

  const assetItemByValidAtSelector = useSelector(selectAssetItemByValidAt, isEqual)
  const { data: userPermissions } = useSelector(selectUserPermission)
  const { data: currentMonthlyClosing } = useSelector(selectCurrentMonthlyClosing, isEqual)
  const eventHistories = useSelector(selectEventHistories, isEqual)
  const hasDividingEvent = hasEventOccurred(eventHistories, ASSET_CHANGE_SITUATION_TYPES.DIVIDING)

  const fixedAssetPermissions = handlePermissionByFeatureId(FEATURES_ID.FIXED_ASSETS, userPermissions)
  const commentPermissions = handlePermissionByFeatureId(FEATURES_ID.COMMENT, userPermissions)
  const dividingPermissions = handlePermissionByFeatureId(FEATURES_ID.DIVIDING, userPermissions)
  const isRetiredOrSold: boolean = getIsRetiredOrSold(asset)

  const dataByValidAt = useGetDatabyValidAt(assetItemByValidAtSelector, asset)

  const breadcrumb = useRenderBreadcrumb(dataByValidAt)

  const isShowingEventForm = checkShowForm([
    isShowImpairmentForm,
    isShowUsageStartedForm,
    isShowRetirementForm,
    isShowMovementForm,
    isShowUpdateUsageStartedForm,
    isShowUpdateRetirementForm,
    isShowUpdateMovementForm,
    isShowSellForm,
    isShowDividingForm,
  ])

  const showFormEvent = (event: string) => {
    const events = new Map([
      [EVENT_TYPES.USAGE_STARTED, setIsShowUsageStartedForm],
      [EVENT_TYPES.RETIREMENT, setIsShowRetirementForm],
      [EVENT_TYPES.MOVEMENT, setIsShowMovementForm],
      [EVENT_TYPES.SOLD, setIsShowSellForm],
      [EVENT_TYPES.TYPE_MODIFICATION, setIsShowTypeModifyForm],
      [EVENT_TYPES.DIVIDING, setIsShowDividingForm],
      [EVENT_TYPES.IMPAIRMENT, setShowImpairmentForm],
    ])
    return events.get(event)
  }

  const dropdownMenuSettings: DropdownMenuSettings[] = useGetDropdownMenuSetting(isRetiredOrSold, asset, showFormEvent)

  const handleCreateUsageEvent = (data: any) => {
    validateUsagedAt(biid, { ...data, dry_run: true })
      .then((result: any) => {
        return checkRetroActiveFixedAsset({
          res: result,
          assetEvent: data,
          currentMonthlyClosing,
          confirmModal,
        })
      })
      .then((isOK: boolean) => {
        isOK && dispatch(createAssetEventUsage(biid, data, hasDividingEvent))
      })
  }

  const handleCreateEventRetirement = (data: any) => {
    dispatch(createAssetEventRetirement(get(asset, 'biid') as string, data, hasDividingEvent))
  }

  const handleCreateEventMovement = (data: any) => {
    dispatch(createAssetEventMovement(get(asset, 'biid') as string, data, hasDividingEvent))
  }

  const handleUpdateEventUsage = async (data: any) => {
    const shouldUpdate = await (!isRetroactiveEvent(data, currentMonthlyClosing) || confirmModal.open({}))
    if (!shouldUpdate) return
    dispatch(updateAssetEventUsage(get(asset, 'biid') as string, get(data, 'id'), data, hasDividingEvent))
  }

  const handleUpdateEventRetirement = (data: any) => {
    dispatch(updateAssetEventRetirement(get(asset, 'biid') as string, get(data, 'id'), data, hasDividingEvent))
  }

  const handleUpdateEventMovement = (data: any) => {
    dispatch(updateAssetEventMovement(get(asset, 'biid') as string, get(data, 'id'), data, hasDividingEvent))
  }

  const handleCreateEventSell = (data: any) => {
    dispatch(createAssetEventSell(get(asset, 'biid') as string, data, hasDividingEvent))
  }

  const handleUpdateEventSell = (data: any) => {
    const assetBiid = get(asset, 'biid') as string
    const assetId = get(data, 'id')

    dispatch(updateAssetEventSell(assetBiid, assetId, data, hasDividingEvent))
  }

  const handleShowUpdateEvent = (eventData: any) => {
    if (!eventData) return

    getCurrentAssetEvent(eventData.biid, eventData.id)
  }

  const handleCloseForm = () => {
    isShowUsageStartedForm && setIsShowUsageStartedForm(false)
    isShowRetirementForm && setIsShowRetirementForm(false)
    isShowMovementForm && setIsShowMovementForm(false)
    isShowUpdateUsageStartedForm && setIsShowUpdateUsageStartedForm(false)
    isShowUpdateRetirementForm && setIsShowUpdateRetirementForm(false)
    isShowUpdateMovementForm && setIsShowUpdateMovementForm(false)
    isShowSellForm && setIsShowSellForm(false)
    isShowUpdateSellForm && setIsShowUpdateSellForm(false)
    dispatch(clearAssetEventErrors())
  }

  const handleReloadCauses = () => {
    dispatch(fetchCauseList())
  }

  useFetchInitData(biid)
  useShowEvent(
    responseAssetEvent,
    setCurrentAssetEvent,
    setIsShowUpdateUsageStartedForm,
    setIsShowUpdateRetirementForm,
    setIsShowUpdateMovementForm,
    setIsShowUpdateSellForm
  )

  // Reset page when data of asset changed
  useEffect(() => {
    handleCloseForm()
  }, [asset]) // eslint-disable-line

  useEffect(() => {
    if (registerEvent === RegisterType.DIVIDED) {
      setIsShowDividingForm(false)
    }
  }, [registerEvent])

  const fetchAsset = () => {
    // eslint-disable-next-line
    const { valid_at, type: assetItemStatus } = queryString.parse(location.search) as AssetSingleViewQueryParams
    const assetStatuses = [ASSET_ITEM_STATUS_PARAM_TYPE.TO_BE_ACQUIRED, ASSET_ITEM_STATUS_PARAM_TYPE.RETIRED_OR_SOLD]
    const isTobeAcquiredOrRetireOrSole = assetStatuses.includes(assetItemStatus!)
    dispatch(fetchAssetByValidAt(biid, valid_at, !isTobeAcquiredOrRetireOrSole, true))
  }

  const handleSubmitChangeAssetType = (payload: any) => {
    Promise.resolve(dispatch(submitModifiedAssetType(biid, payload))).then(() => {
      setIsShowTypeModifyForm(false)
      fetchAsset()
    })
  }

  return (
    <MainTemplate
      containerClassName={ContainerClass}
      breadcrumb={breadcrumb}
      isLoadingApp={assetItemByValidAtSelector.loading}
    >
      <TopMessage
        dataByValidAt={dataByValidAt}
        registerEvent={registerEvent}
        isShowingEventForm={isShowingEventForm}
        isRetiredOrSold={isRetiredOrSold}
        fixedAssetPermissions={fixedAssetPermissions}
        dropdownMenuSettings={dropdownMenuSettings}
        asset={asset}
      />
      <Section className="body">
        <AssetDetail
          data={asset}
          dataByValidAt={dataByValidAt}
          commentPermissions={commentPermissions}
          onUpdateEvent={handleShowUpdateEvent}
          isShowConfirmMessage={isShowConfirmMessage}
          setIsShowConfirmMessage={setIsShowConfirmMessage}
        />
      </Section>
      {isShowUsageStartedForm && (
        <AssetUsageStartedForm
          isShow={isShowUsageStartedForm}
          causes={causes}
          errors={itemHistoryErrors}
          latestEvent={asset}
          onClose={handleCloseForm}
          onSubmit={handleCreateUsageEvent}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowUpdateUsageStartedForm && (
        <AssetUsageStartedForm
          isShow={isShowUpdateUsageStartedForm}
          causes={causes}
          data={currentAssetEvent}
          errors={itemHistoryErrors}
          latestEvent={asset}
          formMode={FORM_MODES.EDIT}
          onClose={handleCloseForm}
          onSubmit={handleUpdateEventUsage}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowRetirementForm && (
        <AssetRetirementForm
          isShow={isShowRetirementForm}
          causes={causes}
          errors={itemHistoryErrors}
          loading={itemHistoryLoading}
          latestEvent={asset}
          onClose={handleCloseForm}
          onSubmit={handleCreateEventRetirement}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowUpdateRetirementForm && (
        <AssetRetirementForm
          isShow={isShowUpdateRetirementForm}
          causes={causes}
          data={currentAssetEvent}
          errors={itemHistoryErrors}
          loading={itemHistoryLoading}
          latestEvent={asset}
          formMode={FORM_MODES.EDIT}
          onClose={handleCloseForm}
          onSubmit={handleUpdateEventRetirement}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowMovementForm && (
        <AssetMovementForm
          isShow={isShowMovementForm}
          causes={causes}
          errors={itemHistoryErrors}
          latestEvent={asset}
          loading={itemHistoryLoading}
          onClose={handleCloseForm}
          onSubmit={handleCreateEventMovement}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowUpdateMovementForm && (
        <AssetMovementForm
          isShow={isShowUpdateMovementForm}
          causes={causes}
          errors={itemHistoryErrors}
          latestEvent={asset}
          loading={itemHistoryLoading}
          data={currentAssetEvent}
          formMode={FORM_MODES.EDIT}
          onClose={handleCloseForm}
          onSubmit={handleUpdateEventMovement}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowSellForm && (
        <AssetEventSellForm
          isShow={isShowSellForm}
          causes={causes}
          errors={itemHistoryErrors}
          latestEvent={asset}
          loading={itemHistoryLoading}
          onClose={handleCloseForm}
          onSubmit={handleCreateEventSell}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowUpdateSellForm && (
        <AssetEventSellForm
          isShow={isShowUpdateSellForm}
          causes={causes}
          errors={itemHistoryErrors}
          latestEvent={asset}
          loading={itemHistoryLoading}
          data={currentAssetEvent}
          formMode={FORM_MODES.EDIT}
          onClose={handleCloseForm}
          onSubmit={handleUpdateEventSell}
          onHandleReloadCauses={handleReloadCauses}
        />
      )}
      {isShowImpairmentForm && (
        <AssetImpairmentForm
          errors={itemHistoryErrors}
          isShow={isShowImpairmentForm}
          setShowForm={setShowImpairmentForm}
        />
      )}
      {isShowDividingForm && (
        <DividingForm
          asset={asset}
          causes={causes}
          isShow={isShowDividingForm}
          permissions={dividingPermissions}
          setShowForm={setIsShowDividingForm}
          onHandleReloadCauses={handleReloadCauses}
          setRegisterEvent={setRegisterEvent}
          setIsShowConfirmMessage={setIsShowConfirmMessage}
        />
      )}
      {isShowTypeModifyForm && (
        <AssetModificationForm
          isShow={isShowTypeModifyForm}
          setShowForm={setIsShowTypeModifyForm}
          onSubmit={handleSubmitChangeAssetType}
        />
      )}
    </MainTemplate>
  )
}
