import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { cleanupCause, createCause } from 'store/settings/cause/actions'

export const useConstructContainer = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    return function cleanup() {
      dispatch(cleanupCause())
    }
  }, [])
}

export const useHandleSubmit = () => {
  const dispatch = useDispatch()
  return useCallback(
    (values: any) => {
      dispatch(createCause(values))
    },
    [dispatch]
  )
}
