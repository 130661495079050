import { DATE_FORMAT } from 'common/constants'
import { Asset } from 'common/models'
import moment from 'moment'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { getAssetSingleViewQueryParams, getMessage, renderDisplayDateMessage } from '../helpers'
import { TopMessage } from '../styles'

export const useRenderTopMessage = (dataByValidAt: Asset, registerEvent: string) => {
  const location = useLocation()
  const { validAt, type } = getAssetSingleViewQueryParams(location.search)
  const acquiredAt = moment(dataByValidAt.acquired_at).format(DATE_FORMAT)
  const eventAt = moment(dataByValidAt.sold_at || dataByValidAt.retired_at || dataByValidAt.impaired_at)
  const displayDateMessage = renderDisplayDateMessage(validAt, type, dataByValidAt)
  const message = getMessage(dataByValidAt, validAt, registerEvent, acquiredAt, eventAt, type)
  return (
    (displayDateMessage || message) && (
      <TopMessage>
        {displayDateMessage && (
          <div>
            <span>{displayDateMessage}</span>
          </div>
        )}
        {message && (
          <div>
            <span>{message}</span>
          </div>
        )}
      </TopMessage>
    )
  )
}
