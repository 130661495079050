import { Client } from '@openfeature/web-sdk'
import { initSlice } from 'aa_common/front-end/zustand'

export enum FeatureFlagNames {
  UseAACommonComponents = 'use-aa-common-components',
  FixedAssetReduction = 'fixed_asset_reduction',
  RefactorPagePhase2 = 'refactor_page_phase2',
  RefactorPagePhase1_4 = 'refactor_page_phase1_4',
  RefactorPagePhase5 = 'refactor_page_phase5',
  ImprovePerformanceFAImportPreviewPage = 'improve-performance-fa-import-preview-page',
  RefactorPagePhase6 = 'refactor_page_phase6',
  RefactorPagePhase7 = 'refactor_page_phase7',
  RegisterAcceleratedDepreciation = 'register-accelerated-depr',
}

export type FeatureNameType =
  | FeatureFlagNames.UseAACommonComponents
  | FeatureFlagNames.FixedAssetReduction
  | FeatureFlagNames.RefactorPagePhase1_4
  | FeatureFlagNames.RefactorPagePhase5
  | FeatureFlagNames.ImprovePerformanceFAImportPreviewPage
  | FeatureFlagNames.RefactorPagePhase2
  | FeatureFlagNames.RefactorPagePhase6
  | FeatureFlagNames.RefactorPagePhase7
  | FeatureFlagNames.RegisterAcceleratedDepreciation

const initState: Partial<Record<FeatureNameType, boolean>> = {}

export const openFeatureSlice = initSlice('open-features', initState)

export const featureList: FeatureType[] = [
  {
    featureName: FeatureFlagNames.UseAACommonComponents,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.FixedAssetReduction,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.RefactorPagePhase1_4,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.RefactorPagePhase5,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.ImprovePerformanceFAImportPreviewPage,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.RefactorPagePhase2,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.RefactorPagePhase6,
    defaultValue: true,
  },
  {
    featureName: FeatureFlagNames.RefactorPagePhase7,
    defaultValue: true,
  },
  {
    featureName: FeatureFlagNames.RegisterAcceleratedDepreciation,
    defaultValue: false,
  },
]

export const isOpenFeatureDevMode = () => {
  const { REACT_APP_ENV, REACT_APP_RELAY_URL } = process.env
  return REACT_APP_ENV === 'local' && !REACT_APP_RELAY_URL
}
export const checkAllFeatures = async (client?: Client) => {
  let i = 0
  const state: Record<string | number, boolean> = {}
  while (i < featureList.length) {
    const current = featureList[i++]
    // if client undefined -> connect error -> set default state
    const featureFlagValue = client?.getObjectValue?.('features-flag', [], {})
    const topLevelFeatureFlagValue = Boolean(client?.getBooleanValue?.(current.featureName, current.defaultValue))

    state[current.featureName] =
      (Array.isArray(featureFlagValue) && featureFlagValue?.includes(current.featureName)) || topLevelFeatureFlagValue
    if (isOpenFeatureDevMode()) {
      state[current.featureName] = true
    }
  }
  openFeatureSlice.setState(state, 'set-open-features')
}

type FeatureType = {
  featureName: FeatureNameType
  defaultValue: any
}
