import { loadNs } from 'common/i18n-config'
import { number, object } from 'yup'

import { FORM_FIELDS } from '../const'
import {
  depreciationSchema,
  getLedgerGeneralValidationErrors,
  resetLedgerGeneralValidationErrors,
} from './deprecication-schema'

const t = loadNs(['components/organisms/assets/asset-form'])
export const assetSchema = object().shape({
  [FORM_FIELDS.BOOK_VALUE_REFERENCE]: number(),
  [FORM_FIELDS.ACQUISITION_COST_REFERENCE]: number(),
  fixed_asset_ledgers: depreciationSchema,
  subsidy_amount: number().test(
    'subsidy_amount',
    t('subsidy_amount_error.over_than_acquisition_cost'),
    (value, context) => {
      const acquisitionCostReference = context.parent[FORM_FIELDS.ACQUISITION_COST_REFERENCE]
      return value === undefined || acquisitionCostReference === undefined || value <= acquisitionCostReference
    }
  ),
})

export function getGeneralValidationErrors(errors?: any) {
  return getLedgerGeneralValidationErrors(errors)
}

export function resetGeneralValidationErrors() {
  resetLedgerGeneralValidationErrors()
}
