import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import moment from 'moment/moment'
import { useEffect } from 'react'

import { useFormReady } from './use-form-ready'

export const useSubsidyAmount = (acquisitionCostReference: number | undefined, manuallyCostUpdated: boolean) => {
  const isFormReady = useFormReady()
  const { values, setFieldValue } = useFormikContext()
  const { isEnable } = useCheckFlag(FeatureFlagNames.FixedAssetReduction)
  const acquiredAt = get(values, 'acquired_at')
  const usageStartedAt = get(values, 'usage_started_at')
  const subsidyAmount = get(values, 'subsidy_amount')
  const reductionAmount = get(values, 'reduction_amount')
  const hasTransition = get(values, 'has_transition')
  const isAcquiredAtSameAsUsageStartedAt = moment(acquiredAt).isSame(moment(usageStartedAt))

  useEffect(() => {
    if (
      isFormReady &&
      !Number.isNaN(subsidyAmount) &&
      !Number.isNaN(acquisitionCostReference) &&
      acquisitionCostReference &&
      subsidyAmount <= acquisitionCostReference &&
      isEnable &&
      isAcquiredAtSameAsUsageStartedAt
    ) {
      setFieldValue('reduction_amount', subsidyAmount)
      setFieldValue('net_acquisition_cost', acquisitionCostReference - subsidyAmount)
    }
  }, [acquisitionCostReference, subsidyAmount, isFormReady, isEnable, isAcquiredAtSameAsUsageStartedAt])

  useEffect(() => {
    if (reductionAmount > subsidyAmount && hasTransition && isEnable) {
      setFieldValue('reduction_amount', subsidyAmount)
    }
  }, [subsidyAmount, reductionAmount, hasTransition, isEnable])

  useEffect(() => {
    if (((manuallyCostUpdated && !acquisitionCostReference) || !isAcquiredAtSameAsUsageStartedAt) && isEnable) {
      setFieldValue('subsidy_amount', 0)
      setFieldValue('reduction_amount', 0)
      setFieldValue('net_acquisition_cost', 0)
    }
  }, [acquisitionCostReference, manuallyCostUpdated, isEnable, isAcquiredAtSameAsUsageStartedAt])
}
