import { Spin } from 'aa_common/front-end/antd'
import { RefactorWarningMessage } from 'common/components/refactor-warning-message'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import { RoleDetailForm } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import React from 'react'

import { useFetchRoleDetail } from './hooks/use-fetch-role-detail'

export const RoleDetailContainer = () => {
  const t = loadNs(['pages/settings/roles'])

  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('add_new_role')} />
  const { loading, data, biid } = useFetchRoleDetail()

  return (
    <>
      <RefactorWarningMessage />
      <MainTemplate breadcrumb={breadcrumb}>
        <Spin loading={loading}>
          <RoleDetailForm biid={biid} type={FORM_MODES.VIEW} data={data} />
        </Spin>
      </MainTemplate>
    </>
  )
}
