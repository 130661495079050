import { ifElse } from 'aa_common/front-end'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { ExciseListContainer } from 'containers/settings/excise-list-container'

import ExciseListPageOld from './excise-setting-page'

const ExciseListPage = () => {
  const { isEnable: isRefactorExciseListPage } = useCheckFlag(FeatureFlagNames.RefactorPagePhase6)
  return <>{ifElse(isRefactorExciseListPage, <ExciseListContainer />, <ExciseListPageOld />)}</>
}

export default ExciseListPage
