import { ifElse } from 'aa_common/front-end'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { ExciseSettingContainer } from 'containers/settings/excise-setting-container'
import React from 'react'

import ExciseSettingPageOld from './excise-setting-page'

const ExciseSettingPage = () => {
  const { isEnable: isRefactorCauseNewPage } = useCheckFlag(FeatureFlagNames.RefactorPagePhase6)
  return <>{ifElse(isRefactorCauseNewPage, <ExciseSettingContainer />, <ExciseSettingPageOld />)}</>
}
export default ExciseSettingPage
