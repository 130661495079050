import styled from '@emotion/styled'

export const Wrapper = styled.div`
  div {
    position: relative;
    z-index: 1000;
    width: 100%;
    line-height: 2.5;
    background-color: rgb(255, 0, 12);
    text-align: center;
    color: #f2ff1b;
    font-weight: bold;
  }
`
